
/* --------------------------------------- */
/* Client Dashboard*/
/* --------------------------------------- */

.client-dashboard h1 {
	position: relative;
	width: fit-content;
	font-size: 2.3rem;
	font-family: "Playfair Display", serif;
	color: rgba(0, 0, 0, 0.8);
	font-weight: 500;
	display: inline-block;
}

.client-dashboard h1::after {
	content: ' ';
	position: absolute;
	display: block;
	bottom: 0;
	right: -5px;
	height: 4px;
	width: 40px;
	background-color: rgb(29, 53, 208);
}

.client-dashboard a {
	text-decoration: none;
}


/* --------------------------------------- */
/* Choose Dashboard View */
/* --------------------------------------- */


#client-type-card {
	overflow: visible !important;
	display: flex;
	flex-direction: column;
	position: relative;
	padding-bottom: 2rem;
	background-color: #ffffff;
	border: 0 solid rgba(0, 0, 0, 0.125);
	border-radius: 0.75rem;
}

#client-type-card h5 {
  	color: #fff;
}

.type-checkbox-container {
	display: flex;
	justify-content: center;
	padding: 2rem 0;
}

.type-checkbox-container .MuiFormGroup-row {
  	column-gap: 2rem;
}

#client-type-card button {
	width: 200px;
	margin-right: 2rem;
	background: linear-gradient(195deg, #c144f3, #4b92ef);
	color: white;
	padding: 10px 0;
}

/* --------------------------------------- */
/* Personal Info Section / My account */
/* --------------------------------------- */


#my-account-info .inactive-account-msg {
	display: flex;
	column-gap: 0.3rem;
}

#my-account-info .inactive-account-msg span{
	font-size: 0.9rem;
	color: #BF0000;
}

#my-account-info .inactive-account-msg svg {
	width: 1.2rem;
	height: 1.2rem;
	fill: #BF0000;
}

#my-account-info .inactive-account-msg svg:hover {
	cursor: pointer;
}

#my-account-info .section {
	margin-bottom: 16px;
	cursor: pointer;
	transition: all 0.3s ease;
	/* border-bottom: 4px solid rgba(50, 50, 93, 0.25); */
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	border-radius: 12px;
	transition: all 0.3s ease;
}

/* #my-account-info .section.open,  
#my-account-info .section:hover {
	border-bottom-color: rgba(29, 53, 208, 0.5);
	box-shadow: rgba(29, 53, 208, 0.19) 0px 10px 20px, rgba(29, 53, 208, 0.23) 0px 6px 6px;
} */
  
#my-account-info  .section-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
}

#my-account-info  .section-header h3 {
	font-size: 1.6rem;
	font-family: "Playfair Display", serif;
	color: rgba(0, 0, 0, 0.9);
	font-weight: 500;
}
  
#my-account-info .section-content {
	padding: 16px;
}
  
#my-account-info .field {
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
}
  
#my-account-info  label {
	width: 200px;
	font-weight: 500;
	font-size: 1.2rem;
	color: rgba(0, 0, 0, 0.9);
	font-family: "Poppins", serif;
}
  
#my-account-info span {
    flex-grow: 1;
    font-family: "Poppins", serif;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 300;
    font-size: 1.1rem;
}


#my-account-info .business-info .business-item {
	padding: 1rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#my-account-info .business-info .field {
	margin-bottom: 0.5rem;
}

#my-account-info .business-info label {
	font-size: 1.1rem;
}

#my-account-info .business-info .MuiChip-root {
	background-color: #BF0000;
	height: 25px;
}

#my-account-info .business-info .MuiChip-root span {
	color: #fff;
	font-size: 0.9rem;
	font-weight: 500;
}

.business-menu li, .business-menu a {
  	column-gap: 1rem;
    color: #333;
    font-family: 'Poppins';
    font-size: 1.1rem;
    font-weight: 400;
}

#client-business-dialog .client-view-business-dialog h6 {
	text-align: left;
	font-size: 1.2rem;
    font-family: 'Poppins';
    color: #333;
    font-weight: 500;
	letter-spacing: 1px;
}

#client-business-dialog .client-view-business-dialog p {
	text-align: left;
	font-size: 1.1rem;
	color: #545454;
	font-family: Poppins;
}

#client-business-dialog .other-users-title {
	text-transform: uppercase;
	font-weight: 600;
	font-family: 'Poppins';
	font-size: 1.2rem;
	color: #333;
	letter-spacing: 1px;
}

#client-business-dialog .users-list span {
	font-family: 'Poppins';
    color: #545454;
    font-weight: 500;
    font-size: 1.1rem;
}

#client-business-dialog .users-list p{
	font-family: 'Poppins';
    font-size: 1.1rem;
	font-style: italic;
}

/* --------------------------------------- */
/* Merged Client Personal Info View*/
/* --------------------------------------- */

#merged-account-info .inactive-account-msg {
	display: flex;
	column-gap: 0.3rem;
}

#merged-account-info .inactive-account-msg span{
	font-size: 0.9rem;
	color: #BF0000;
}

#merged-account-info .inactive-account-msg svg {
	width: 1.2rem;
	height: 1.2rem;
	fill: #BF0000;
}

#merged-account-info .inactive-account-msg svg:hover {
	cursor: pointer;
}

#merged-account-info .section {
	margin-bottom: 16px;
	cursor: pointer;
	transition: all 0.3s ease;
	border-bottom: 4px solid rgba(50, 50, 93, 0.25);
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	border-radius: 12px;
	transition: all 0.3s ease;
}

#merged-account-info .section.open,  
#merged-account-info .section:hover {
	border-bottom-color: rgba(29, 53, 208, 0.5);
	box-shadow: rgba(29, 53, 208, 0.19) 0px 10px 20px, rgba(29, 53, 208, 0.23) 0px 6px 6px;
}
  
#merged-account-info  .section-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
}

#merged-account-info  .section-header h3 {
	font-size: 1.3rem;
	font-family: "Playfair Display", serif;
	color: rgba(0, 0, 0, 0.9);
	font-weight: 500;
}
  
#merged-account-info .section-content {
	padding: 16px;
}
  
#merged-account-info .field {
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
}
  
#merged-account-info  label {
	width: 200px;
	font-weight: 500;
	font-size: 1.3rem;
	color: rgba(0, 0, 0, 0.9);
	font-family: "Poppins", serif;
	/* letter-spacing: 1px; */
}
  
#merged-account-info span {
    flex-grow: 1;
    font-family: "Poppins", serif;
    color: rgba(0, 0, 0, 0.9);
    font-weight: 300;
    font-size: 1.1rem;
}


#merged-account-info .business-info .business-item {
	padding: 1rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#merged-account-info .business-info .field {
	margin-bottom: 0.5rem;
}

#merged-account-info .business-info label {
	font-size: 1.1rem;
}



/* --------------------------------------- */
/* Questionnaire view */
/* --------------------------------------- */

#questionnaire-client-wrapper .questionnaire-items-container {
  	min-height: 380px;
}

#questionnaire-client-wrapper .questionnaire-item {
	height: fit-content;
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	padding: 1rem;
	border-radius: 12px;
	background-color: #fff;
}

#questionnaire-client-wrapper .questionnaire-item .content-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 0.5rem;
}

#questionnaire-client-wrapper .questionnaire-item img {
	width: 80px;
}

#questionnaire-client-wrapper .questionnaire-item p {
	font-size: 1rem;
	font-family: "Poppins";
	color: #333;
	font-weight: 500;
}

#questionnaire-client-wrapper .questionnaire-item .content-box button {
	background-color: #293693;
	color: white;
	text-transform: capitalize;
	font-family: "Poppins";
	font-size: 1rem;
	padding: 0.3rem 1rem;
}

#questionnaire-view-dialog h5 {
	margin-bottom: 1.5rem;
	font-family: "Poppins";
	font-weight: 500;
	font-size: 1.7rem;
	color: #333;
}

#questionnaire-view-dialog .group-heading{
	font-family: "Poppins";
	letter-spacing: 1px;
	color: #333;
	font-weight: 500;
	color: rgb(84, 84, 84);
}

/* --------------------------------------- */
/* Documents / Folders View */
/* --------------------------------------- */

#client-view-folders-header  {
	margin-bottom: 50px;
	padding: 0 1rem;
}

#client-view-folders-header button {
	background-color: rgba(255, 150, 22, 1);
	color: white;
	border: none;
	padding: 10px 48px;
	border-radius: 12px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#client-view-folders-header  button:hover {
	background-color: rgba(255, 120, 0, 1);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

#client-folders-container {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

#client-folders-container h6 {
	font-size: 14px;
	font-weight: 500;
	font-family: "Poppins";
	color: #222222;
}

#client-folders-container .MuiGrid-item {
  	text-align: center;
}

#client-folders-container .MuiTypography-caption {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 16px;
	font-weight: 500;
	background: linear-gradient(to bottom right, rgb(97, 97, 97), rgb(26, 25, 25)) !important;
	padding: 2px 5px;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.8;
}

#client-folders-container .box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 200px;
	max-width: 230px;
	background-color: #c4c3c328;
	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.3s ease-in-out;
	cursor: pointer;
}

#client-folders-container .box:hover {
  	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

#client-folders-container .box img {
	width: 100px;
	height: 100px;
	object-fit: contain;
	margin-bottom: 10px;
}

/* --------------------------------------- */
/* Documents / Files View */
/* --------------------------------------- */

#client-files-container {

}

#client-files-container .MuiBreadcrumbs-li p {
	text-decoration: none;
	color: #444;
	font-family: 'Poppins';
	font-weight: 500;
}

#client-files-container .MuiBreadcrumbs-li .breadcrumb-main {
	color: #777;
	font-weight: 400;
}

#client-files-container .MuiBreadcrumbs-li .breadcrumb-main:hover {
	cursor: pointer;
	text-decoration: underline;
}

#client-files-container .MuiMenuItem-root {
  	font-weight: bold;
}

#client-files-container .MuiSelect-root {
  	padding: 10px;
}

#client-files-container .year-select-container {
	min-width: 150px;
  height: 45px;
}

#client-files-container .year-select-container .MuiInputBase-root {
  height: 45px;
}

#client-files-container .top-section {
	display: flex;
	justify-content: space-between;
	width: 90%;
}

#client-files-container .breadcrumb-container {
  	flex-grow: 1;
}

#client-files-container .year-select-container {
  	min-width: 150px;
}

#client-files-container .MuiListItemSecondaryAction-root {
	display: flex;
	align-items: center;
}

#client-files-container .file-list {
	width: 90%;
	min-height: 488px;
	margin-top: 1rem;
}

#client-files-container .file-list-item .file-icon {
	width: 50px;
	padding-right: 1rem;
}

#client-files-container .file-list-item {
	border-bottom: 1px solid #ccc;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
	border-radius: 5px;
	margin-bottom: 0.5rem;
	transition: transform 0.3s ease-in-out;
	font-family: "Poppins";
}

#client-files-container .file-list-item a {
	width: 100%;
}

#client-files-container .file-list-item:hover {
  	transform: scale(1.01);
}

#client-files-container .file-list-item a {
	display: flex;
	align-items: center;
	text-decoration: none;
}

#client-files-container .MuiListItemText-primary {
  	color: black;
}

#client-files-container .file-list-item .MuiIconButton-root {
  	padding: 8px;
}

#client-files-container .file-list-item .MuiListItemSecondaryAction-root > * + * {
  	margin-left: 8px;
}

#client-files-container .files-go-back-btn {
	border: 1px solid #682286;
    background-color: transparent;
    font-size: 1rem;
    text-transform: capitalize;
    font-family: 'Poppins';
    color: #682286;
    padding: 0.2rem 1rem;
    border-radius: 21px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	transition: all 0.5s ease;
}

#client-files-container  .files-go-back-btn:hover {
	background-color: #682286;
	color: #fff;
}

/* --------------------------------------- */
/* Documents / Upload Dialog */
/* --------------------------------------- */

#client-documents-upload-dialog .hidden-file {
	display: none;
}

#client-documents-upload-dialog .hidden-file {
	display: none;
}

#client-documents-upload-dialog .upload-dialog-header .MuiChip-root {
	margin-right: 16px;
	background-color: #FFA500;
	color: #FFFFFF;
}

#client-documents-upload-dialog .upload-dialog-header .MuiIconButton-root {
  	color: #000000;
}

#client-documents-upload-dialog .upload-dialog-header .MuiIconButton-root:hover {
  	color: #FFA500;;
}

#client-documents-upload-dialog .upload-dialog-header .MuiIconButton-root:focus {
  	color: #FFA500;;
}

#client-documents-upload-dialog .dropZone {
  	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 90%;
	margin: 0 auto;
	padding: 2rem 0;
	border: 1px dashed rgb(45,168,253);
	border-radius: 12px;
	background: rgb(34,193,195);
	background: linear-gradient(0deg, rgba(34,193,195,0) 0%, rgba(45,168,253,0.3) 100%);
}

#client-documents-upload-dialog .text-wrapper {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
}
  
#client-documents-upload-dialog .text-wrapper img{
	width: 50px;
}
  
#client-documents-upload-dialog .text-wrapper button {
	width: 60%;
	background-color: rgb(0, 140, 255) !important;
	color: #fff;
	padding: 0.5rem!important;
	font-size: 0.9rem !important;
	text-transform: capitalize;
}

#client-documents-upload-dialog .preview-files .MuiIconButton-root svg {
	width: 0.9rem;
	height: 0.9rem;
}

#client-documents-upload-dialog .preview-files .MuiIconButton-root:hover {
  	color: #000;
}

#client-documents-upload-dialog ul {
	padding-top: 0;
	max-height: 210px;
	overflow-y: scroll;
}

#client-documents-upload-dialog ul::-webkit-scrollbar {
  	width: 3px; 
}

#client-documents-upload-dialog ul::-webkit-scrollbar-track {
  	background-color: #F5F5F5; 
}

#client-documents-upload-dialog ul::-webkit-scrollbar-thumb {
	background-color: #888; 
	border-radius: 5px; 
}

#client-documents-upload-dialog ul::-webkit-scrollbar-thumb:hover {
	background-color: #555;
	cursor: pointer;
}

#client-documents-upload-dialog ul::-webkit-scrollbar-thumb:focus {
  	cursor: pointer;
}

#client-documents-upload-dialog ul li {
  	width: 98%;
}

.admin-uploaded-file {
	margin-bottom: 0.5rem;
	border: #989898 1px solid;
	border-radius: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.admin-uploaded-file span{
	font-size: 0.8rem;
	font-weight: 500;
	font-family: "Poppins";
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#client-documents-upload-dialog .file-icon {
  	width: 30px;
}

#client-documents-upload-dialog .add-files-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#client-documents-upload-dialog .add-files-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0;
}



/* --------------------------------------- */
/* Settings Page */
/* --------------------------------------- */

#settings-page .MuiTabs-flexContainer {
  	align-items: flex-start;
}

#settings-page .MuiTabs-root {
	height: 100%;
  	border-right: 1px solid #868686;
}

#settings-page  .MuiTabs-indicator {
  	display: none;
}

#settings-page .MuiTabs-flexContainer button {
	column-gap: 0.5rem;
	text-transform: none;
	font-size: 1.1rem;
	font-family: "Poppins";
	color: #868686;
}

#settings-page .MuiTabs-flexContainer .Mui-selected {
  	color: #293693;
}

#settings-page h3 {
	font-family: "Poppins";
	font-size: 1.5rem;
	color: #333;
	font-weight: 500;
}

#settings-page .MuiTabPanel-root {
  	padding: 0;
}

.settings-business-item {
	padding: 1rem;
	border: 1px solid #868686;
	border-radius: 5px;
}

#settings-page .settings-container {
	background: white;
	border-radius: 10px;
	padding: 1rem;
	width: 95%;
	margin: 0 auto;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#settings-page .edit-button {
	padding: 5px 15px;
	text-transform: capitalize;
	font-family: "Poppins";
	font-size: 1rem;
	background-color: rgba(255, 150, 22, 1);
	color: #fff;
	font-weight: 500;
	transition: all 0.3s ease;
}

#settings-page .edit-button:hover {
	background-color: rgba(255, 120, 22, 1);
}

#settings-page .save-btn {
	width: 145px;
	background-color: #1976D2;
	border: 1px solid #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#settings-page .save-btn:disabled {
	opacity: 0.7;
}

#settings-page .save-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0;
}

#settings-page .cancel-btn {
	width: 145px;
	background-color: transparent;
	border: 1px solid #4b4b4b74;
	color: #4B4B4B; 
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#settings-page .cancel-btn:hover {
	background-color: #4B4B4B;
	color: white;
	border: 1px solid #4B4B4B; 
}

#settings-page .password-requirements-container p {
	display: flex;
	align-items: center;
	font-size: 1rem;
	column-gap: 0.5rem;
	font-weight: 500;
}

#settings-page .password-requirements-container p.green {
	color: rgb(6, 111, 0);
}

#settings-page .password-requirements-container svg {
	font-size: 1.2rem;
}

#settings-page .error-msg {
	padding-bottom: 1rem;
	text-align: center;
	font-size: 1rem;
	color: red;
}



/* --------------------------------------- */
/* Notifications */
/* --------------------------------------- */

/* Wrapper for the notifications list */
.notifications-wrapper {
	padding-right: 16px
}
  
.notification-item {
	background-color: #f9f9f9;
	border-radius: 8px;
	margin-bottom: 12px;
	padding: 12px;
	transition: background-color 0.3s ease;
	align-items: flex-start;
}
  
.notification-item.unread {
	background-color: #eaf3ff;
	border-left: 4px solid #1976d2;
}
  
  
.notification-item.read {
	opacity: 0.85;
}
  
.notification-item h6 {
	font-weight: 600;
	font-size: 1rem;
	color: #1a1a1a;
	font-family: "Poppins";
}
  
.notification-item p {
	font-size: 0.95rem;
	color: #444;
	font-family: "Poppins";
}
  

#notifications-wrapper .notification-item .notification-date {
	display: block;
	margin-top: 4px;
	color: #4d4d4d;
	font-size: 12px;
	font-family: "Poppins";
	font-weight: 400;
}
  
.notification-item .MuiIconButton-root {
	margin-left: 12px;
}
  
.notifications-wrapper .MuiButton-outlined {
	text-transform: none;
	font-weight: 500;
	border-radius: 6px;
}
 
.notifications .no-notifications-text {
	color: #555;
	font-size: 1rem;
	margin-left: 16px;
	font-family: "Poppins";
}
  


