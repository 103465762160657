/* .hero-container{
  object-fit: cover;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 100%;
  position: relative;
  display: flex;
  background-clip: padding-box;
  background-origin: padding-box;
  text-align: right;
  color: #111111;
  min-height: 660px;
  background-image: url("../assets/homepage/fff.png");
  background-position: 100% 50%;
  overflow: visible;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
  min-width: 58.33333333%;
} */

/* #landing-page {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 216px 0px 120px 0px;
  position: relative;
  overflow: hidden;
  margin-top: -100px;
}

#landing-page:after {
  content: '';
  background-image: url(../assets/homepage/banner-dec-left.png);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 100px;
  width: 212px;
  height: 653px;
  z-index: -1;
}

#landing-page:before {
  content: '';
  background-image: url(../assets/homepage/slider-dec-right.png);
  background-repeat: no-repeat;
  position: absolute;
  right: -50px;
  top: 100px;
  width: 1159px;
  height: 797px;
  z-index: -1;
} */

#landing-page .MuiContainer-root {
  max-width: 1320px;
}

.main-banner {
  margin-top: -100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 226px 0px 120px 0px;
  position: relative;
  overflow: hidden;
}

.main-banner:after {
  content: '';
  background-image: url(../assets/homepage/slider-left-dec.jpg);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 60px;
  width: 262px;
  height: 625px;
  z-index: 1;
}

.main-banner:before {
  content: '';
  background-image: url(../assets/homepage/slider-right-dec.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 60px;
  width: 1159px;
  height: 797px;
  z-index: -1;
}

.hero-container {
  max-width: 1320px;
  margin: 0 auto;
}

.heroTextContainer {
  z-index: 2;
}

.heroTextContainer h1 {
  font-family: "Poppins";
  text-transform: capitalize;
  font-size: 3.8rem;
  line-height: 4rem;  /* Converted from pixel to rem for scalability */
  font-weight: 700;
  color: #2a2a2a;
  margin-bottom: 20px;
  text-align: left;
}

.heroTextContainer span {
  font-family: "Poppins";
  display: block;
  text-align: right;
  font-size: 5rem;
  line-height: 4rem;
  font-weight: 600;
  padding-left: 10px;
  color: orange;
}

.heroTextContainer p {
  margin-bottom: 30px;
  margin-right: 45px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-family: 'Poppins';
  color: #2a2a2a;
}

.hero-image-container {
  text-align: right;
  position: relative;
  z-index: 20;
}

.hero-image {
  width: 100%;
  max-width: 593px;
}


#landing-page h2 {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  color:rgba(0, 0, 0, 0.8);
  font-family: "Poppins", serif;

}

/*.hero-image {
  width: 80%;
}

.heroTextContainer  {
  padding: 1rem;
}

.heroTextContainer h1 {
  text-align: left;
  font-size: 4rem;
  font-weight: 500;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.8);
  font-family: "Poppins", serif;
}

.heroTextContainer span {
  font-size: 4rem;
  font-weight: 500;
  padding-left: 10px;
  color: orange;
}

.heroTextContainer p {
  color: rgba(0, 0, 0, 0.8);
  font-family: "Poppins", serif;
  font-weight: 500;
  font-size: 1.3rem;
}

.how-it-works-button {
  padding: 1rem 3rem !important;
  border-radius: 50px !important;
  text-transform: capitalize !important;
  font-size: 1.2rem !important;
  font-family: "Poppins", serif !important;
  font-weight: 500;
  white-space: nowrap;
  background: linear-gradient(90deg, rgb(255, 150, 22) 0%, rgba(237,18,124,1) 30%, rgba(0,140,255,1) 82%) !important;
}

.heroTextContainer span svg {
  fill: #fff;
  font-size: 1.7rem !important;
} */


/* Services  */

.service-items-wrapper h5 {
  width: 70%;
  margin: 0 auto !important;
  text-align: center;
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-weight: 400;
  color:rgba(0, 0, 0, 0.7);
  font-family: "Poppins", serif;
}

.service-items-wrapper .service-item-container {
  padding: 2rem 0;
  justify-content: space-between;
  align-items: center;
}

.with-background {
  width: 100%;
  background-color: #FBFBFF;
}

.service-items-wrapper .service-item-container img { 
  width: 100%;
}

.service-items-wrapper .service-item-container h3{ 
  	font-size: 3rem;
    font-family: "Inter", serif;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    margin-bottom: 0.5rem;
	  display: inline-block;
    position: relative;
    margin-bottom: 3px;
}

.service-items-wrapper .service-item-container h3::after {
  background: rgba(155,34,252,0.14);
  background: linear-gradient(90deg, rgba(155,34,252,0.14) 0%, rgba(98,98,210,0.14) 47%, rgba(27,96,250,0.14) 100%);
  bottom: -10%;
  right: -8px;
  content: ' ';
  display: inline-block;
  height: 60%;
  width: 100%;
  margin-top: 10px;
  margin-left: -5px;
  margin-right: -3px;
  position: absolute;
}

.service-items-wrapper .service-item-container .service-item-desc{ 
  width: 100%;
  text-align: left;
  font-size: 1rem;
  padding-top: 1rem;
  line-height: 1.5rem;
  font-family: "Poppins", serif;
  font-weight: 400;
  color:rgba(0, 0, 0, 0.8);
}

.service-items-wrapper .service-item-container .bullet-point {
	align-items: center;
}

.service-items-wrapper .service-item-container .bullet-point img{
	width: 30px;
	padding-right: 1rem;
}
.service-items-wrapper .service-item-container .bullet-point p{
	font-family: "Poppins", serif;
  	font-weight: 400;
	font-size: 1rem;
 	color:rgba(0, 0, 0, 0.8);
}

.service-items-wrapper .service-item-container a {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 10px;
	font-weight: bold;
	font-size: 0.9rem;
	font-family: "Poppins", serif;
	padding: 12px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}


/* Free Quote Section */

.free-quote-section {
  background-image: url(../assets/homepage/quote-bg-v3.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 120px 0px;
  text-align: center;
  position: relative;
  z-index: 2;
  margin-top: 130px;
}

.free-quote-section h3 {
  margin-bottom: 0.5rem;
  color: #fff;
  font-family: "Poppins", serif;
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
}

.free-quote-section p {
  width: 50%;
  margin: 0 auto;
  font-size: 1.1rem;
  color: #fff;
  font-family: "Poppins", serif;
  text-align: center;
  font-weight: 400;
}

.free-quote-section img {
  position: absolute;
  width: 200px;
  right: 100px;
}


.free-quote-section .get-started-button {
  background: #fff;
  color: #1704d1;
  text-transform: capitalize;
  font-family: 'Poppins';
  font-size: 1.3rem;
  font-weight: 500;
  padding: 12px 40px;
  border-radius: 31px;
  transition: all 0.3s ease;
} 

.free-quote-section .get-started-button:hover {
  color: #FBFBFF;
  background: #0d026ca0;
} 


/* FAQ section */

.faq-section {
  margin-top: 5rem;
	justify-content: center;
	column-gap: 3rem;
}

#landing-page .faq-section h2 {
	width: 100%;
	text-align: left;
	line-height: 3rem;
}


.faq-item {
	width: 100%;
	margin: 1rem auto !important;
	padding: 5px;
	background-color: #fff;
	border-radius: 10px !important;
	box-shadow: 0 14px 5rem 0 rgba(137,149,189,.1) !important;
  /* border-width: 2px !important; */
  /* border-style: solid !important; */
  /* border-image: linear-gradient(to right, rgb(255, 150, 22), rgba(237,18,124,1), rgba(0,140,255,1)) 1; */
}

.faq-item::before {
	background-color: transparent !important;
}

.faq-item h4{
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
  font-family: "Poppins", serif;
}

.faq-item p{
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 400;
}


/* Contact us */

.contact-us-wrapper {
  width: 80% !important;
  margin: 0 auto;
  padding-bottom: 100px;
}

.contact-us-wrapper h3 {
  font-size: 2.3rem;
  color:rgba(0, 0, 0, 0.8);
  font-weight: 600;
  font-family: "Playfair Display", serif;
}

.contact-us-wrapper p{
  font-size: 1.2rem;
  line-height: 1.5rem;
  color:rgba(0, 0, 0, 0.7);
  font-family: "Poppins", serif;
}

.link-container a, .link-container p {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-size: 1.2rem;
  font-family: "Poppins", serif;
}

.contact-us-wrapper a:hover{
  text-decoration: underline;
}

.contact-us-button {
  width: 100%;
  margin-top: 20px !important;
  padding: 0.875rem 2rem !important;
  background-color: rgba(252, 164, 0, 1) !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
  color: #fff !important;
  font-family: "Poppins", serif !important;
}

/* @media (max-width: 600px) {
  #landing-page .heroTextContainer, #landing-page .hero-image {
    width: 100%;
    max-width: 100%;
  }

  #landing-page .heroTextContainer h1 {
    font-size: 1.8rem;
  }

  #landing-page .heroTextContainer span {
    font-size: 1.8rem;
  }

  #landing-page .heroTextContainer p {
    font-size: 1.2rem;
  }


} */

@media (max-width: 1240px) {

  #landing-page .heroTextContainer h1 {
    font-size: 3rem;
  }

  #landing-page .heroTextContainer span {
    font-size: 3rem;
  }

  #landing-page .heroTextContainer p {
    font-size: 1.2rem;
  }

}

@media (max-width: 980px) {

  #landing-page h2 {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }

  .main-banner:before {
    display: none;
  }

}

@media (max-width: 890px) {
  #landing-page .heroTextContainer h1 {
    font-size: 2rem;
  }

  #landing-page .heroTextContainer span {
    font-size: 2rem;
  }

  #landing-page .heroTextContainer p {
    font-size: 1rem;
  }

}

@media (max-width: 600px) {

  #landing-page .hero-container {
    margin-bottom: 2rem;
  }
  #landing-page .heroTextContainer h1 {
    font-size: 2.3rem;
    text-align: center;
  }

  #landing-page .heroTextContainer span {
    font-size: 2.3rem;
  }

  #landing-page .heroTextContainer p {
    font-size: 0.9rem;
    text-align: center;
  }

  #landing-page .hero-image img {
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  #landing-page h2 {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  #landing-page .service-items-wrapper  {
    margin-top: 2rem
  }

  .service-items-wrapper h5 {
    width: 95%;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }

  .service-items-wrapper .service-item-container div {
    padding: 0;
  }

  .service-items-wrapper .service-item-container img {
    display: block;
    width: 85%;
    margin: 0 auto;
  }

  .service-items-wrapper .service-item-container h3 {
    font-size: 1.9rem;
  }

  .service-items-wrapper .service-item-container .service-item-desc {
    font-size: 0.9rem;
  }

  .service-items-wrapper .service-item-container .bullet-point {
    flex-wrap: nowrap;
  }

  .service-items-wrapper .service-item-container .bullet-point img  {
    padding-right: 0.5rem;
    width: 20px;
  }

  .service-items-wrapper .service-item-container .bullet-point p {
    font-size: 0.9rem;
  }

  .service-item-container.item-2 {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
  }

  #landing-page .free-quote-container h2 {
    text-align: center;
  }

  .free-quote-container p {
    text-align: center;
    font-size: 0.9rem;
  }

  .free-quote-container div{ 
    justify-content: center;
  }

  #landing-page .faq-section h2 {
    text-align: center;
  }

}

/* .u-image-1 {
  object-fit: cover;
  vertical-align: middle;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 100%;
  position: relative;
  display: flex;
  background-clip: padding-box;
  background-origin: padding-box;
  text-align: right;
  color: #111111;
  min-height: 660px;
  background-image: url("../assets/homepage/fff.png");
  background-position: 100% 50%;
  overflow: visible;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
  min-width: 58.33333333%;
} */
