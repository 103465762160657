@keyframes slideInFromLeft {
	0% {
	  transform: translateX(-100%);
	  opacity: 0;
	}
	100% {
	  transform: translateX(0);
	  opacity: 1;
	}
}

#free-quote-page {
	margin-bottom: 5rem;
	position: relative;
}

#free-quote-page .main-grid:before {
    content: '';
    background-image: url(../assets/right-background.jpg);
    background-repeat: no-repeat;
    position: absolute;
    right: 20px;
    top: 0px;
    width: 65%;
    height: 961px;
    z-index: -1;
	opacity: 0.7;
}

#free-quote-page h1 {
	width: fit-content;
	display: inline-block;
	position: relative;
	font-size: 3rem;
	font-family: "Poppins";
	color: rgba(0, 0, 0, 0.8);
	font-weight: 600;
}

#free-quote-page h1::after {
	background: rgba(155,34,252,0.14);
	background: linear-gradient(90deg, rgba(155,34,252,0.14) 0%, rgba(98,98,210,0.14) 47%, rgba(27,96,250,0.14) 100%);
	bottom: -10%;
	right: -8px;
	content: ' ';
	display: inline-block;
	height: 60%;
	width: 100%;
	margin-top: 10px;
	margin-left: -5px;
	margin-right: -3px;
	position: absolute;
}

#free-quote-page .left-part {
  row-gap: 3rem; 
}

#free-quote-page .left-part .item .item-container {
	width: 70%;
    align-items: center;
    column-gap: 1rem;	
	padding: 2rem 1rem;
	border-radius: 12px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#free-quote-page .left-part .item .item-container-1 {
	background-color: rgba(186, 212, 242, 0.586);
	animation: slideInFromLeft 1s forwards;
}

#free-quote-page .left-part .item .item-container-2 {
	background-color: rgba(237, 200, 243, 0.368);
	animation: slideInFromLeft 1.5s forwards;
}

#free-quote-page .left-part .item .item-container-3 {
	background-color: rgba(243, 215, 170, 0.521);
	animation: slideInFromLeft 2s forwards;
}

#free-quote-page .left-part .item-2 {
	justify-content: flex-end;
}

#free-quote-page .left-part .item .item-container h3 {
    text-align: center;
	padding-bottom: 0.3rem;
	font-family: "Poppins";
	font-weight: 600;
	color: #333;
	font-size: 1.4rem;
}

#free-quote-page .left-part .item .item-container p {
    text-align: center;
	font-family: "Poppins";
	font-size: 1rem;
	color: #080808;
}

/* form */

#free-quote-page .error-msg {
    text-align: center;
    padding-bottom: 1rem;
    color: #BF0000;
    font-family: "Poppins";
    font-size: 1.1rem;
}

#free-quote-page .success-msg {
    text-align: center;
    padding-bottom: 1rem;
    color: #077701;
    font-family: "Poppins";
    font-size: 1.1rem;
}

#free-quote-page .free-quote-wrapper .client-type-btn-wrapper {
	width: 100%;
	justify-content: space-between;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#free-quote-page .free-quote-wrapper .client-type-btn-wrapper button {
	width: 50%;
	padding: 7px;
	text-transform: capitalize;
	font-size: 1.3rem;
	border: 2px solid #1976D2;
	font-weight: 600;
	color: #4c4c4c;
}

#free-quote-page .free-quote-wrapper .client-type-btn-wrapper button.active {
	background: radial-gradient(circle, rgba(126,181,235,1) 0%, rgba(75,157,238,1) 37%, rgba(14,104,193,1) 100%);
	border: 1px solid #1976D2;
	color: #fff;
}

#free-quote-page .free-quote-wrapper .upload-btn {
	text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Poppins';
	background: radial-gradient(circle, rgba(126,181,235,1) 0%, rgba(75,157,238,1) 37%, rgba(14,104,193,1) 100%);
	border: 1px solid #1976D2;
	color: #ffffff; 
	padding: 7px 35px;
	transition: all 0.3s ease;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#free-quote-page .free-quote-wrapper .upload-btn:hover  {
    box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

#free-quote-page .free-quote-wrapper .free-quote-submit-btn {
	text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Poppins';
    background-color: #1976D2;
	border: 1px solid #1976D2;
    color: #ffffff; 
	padding: 7px 35px;
	border-radius: 25px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: all 0.3s ease;
}

#free-quote-page .free-quote-wrapper .free-quote-submit-btn:hover  {
    box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

@media (max-width: 1140px) {

	#free-quote-page .left-part .item .item-container {
		padding: 2rem 0.5rem;
	}

	#free-quote-page .left-part .item .item-container img {
		width: 55px;
	} 

	#free-quote-page .left-part .item .item-container h3 {
		font-size: 1.2rem;
	}

	#free-quote-page .left-part .item .item-container p {
		font-size: 0.9rem;
	}

}

@media (max-width: 980px) {

	#free-quote-page .main-grid {
		flex-direction: column;
		align-items: center;
		row-gap: 3rem;
	}

	#free-quote-page .left-part, 
	#free-quote-page .right-part {
		width: 90%;
		max-width: 90%;
	} 

	#free-quote-page .left-part .item .item-container h3 {
		font-size: 1.2rem;
	}

	#free-quote-page .left-part .item .item-container p {
		font-size: 0.9rem;
	}

}

@media (max-width: 640px) {

	#free-quote-page .left-part {
		row-gap: 1.5rem;
	}

	#free-quote-page .left-part .item .item-container {
		width: 100%;
	}

}

@media (max-width: 480px) {

	#free-quote-page .header-container {
		margin-bottom: 3rem;
	}

	#free-quote-page h1 {
		font-size: 2.2rem;
	}

	#free-quote-page .left-part, 
	#free-quote-page .right-part {
		width: 95%;
		max-width: 95%;
	} 

	#free-quote-page .left-part .item .item-container {
		flex-direction: column;
		padding: 1rem;
		row-gap: 1rem;
	}

	#free-quote-page .left-part .item .item-container img {
		width: 64px;
	} 

}


