

#merge-account-wrapper .clients-container .MuiAutocomplete-root {
    margin-bottom: 1rem;
}

#merge-account-wrapper h2  {
    margin-bottom: 1rem;
    font-size: 1.7rem;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    color: rgb(19, 19, 19);
}

.MuiAutocomplete-option {
    flex-direction: column;
    align-items: flex-start !important;
}


.merge-client-name {
    font-family: "Poppins" !important;
    color: #333 !important;
    font-weight: 500 !important;
}

.merge-client-email {
    font-family: "Poppins" !important;
    color: #797878 !important;
    font-weight: 400 !important;
    font-size: 0.9rem !important;
}

#merge-account-wrapper .warning-msg-wrapper {
    display: flex;
}

#merge-account-wrapper .warning-msg-wrapper svg {
    fill: #BF0000;
}

#merge-account-wrapper .warning-message {
    padding-left: 10px;
    font-size: 16px;
    font-family: 'Poppins';
    color: #BF0000;
}

#merge-account-wrapper .warning-message span {
    font-weight: 500;
}

#merge-account-wrapper .spouse-info {
    margin-top: 2rem;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

#merge-account-wrapper .spouse-info h4 {
    margin-bottom: 0.5rem;
    font-family: "Poppins" !important;
    color: #333 !important;
    font-weight: 500 !important;
    font-size: 1.2rem;
}

#merge-account-wrapper .spouse-info .item {
    display: flex;
    align-items: center;
}

#merge-account-wrapper .spouse-info h5 {
    width: 80px;
    font-family: "Poppins" !important;
    color: #333 !important;
    font-weight: 500 !important;
    font-size: 1rem;
}

#merge-account-wrapper .spouse-info p {
    font-family: "Poppins" !important;
    color: #333 !important;
    font-size: 1rem;
}

#merge-account-wrapper .merge-businesses h2 {
    margin-bottom: 0;
    font-size: 1.7rem;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    color: rgb(19, 19, 19);
}

#merge-account-wrapper .merge-businesses .desc-msg {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-family: "Poppins";
    font-weight: 400;
    color: rgba(19, 19, 19, 0.555);
}

#merge-account-wrapper .merge-businesses .business-item {
    border-radius: 5px;
    margin-bottom: 1rem;
    padding: 1rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#merge-account-wrapper .merge-businesses .business-item .info-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
    padding-bottom: 0.5rem;
}

#merge-account-wrapper .merge-businesses .business-item .info-item h5 {
    width: 150px;
    font-weight: 500;
	font-size: 1.1rem;
	color: rgba(0, 0, 0, 0.9);
	font-family: "Poppins", serif;
}

#merge-account-wrapper .merge-businesses .business-item .info-item span {
    flex-grow: 1;
	font-family: "Poppins", serif;
  	color: rgba(0, 0, 0, 0.9);
  	font-weight: 300;
	font-size: 1.1rem;
}

#merge-account-wrapper .merge-businesses .business-item .info-name-item:nth-child(3) {
    border-top: 1px solid rgba(128, 128, 128, 0.444);
    padding-top: 0.5rem;
}

#merge-account-wrapper .merge-businesses .business-item .info-name-item h6 {
    width: 150px;
    font-weight: 500;
	font-size: 1.1rem;
	color: rgba(0, 0, 0, 0.9);
	font-family: "Poppins", serif;
}

#merge-account-wrapper .merge-businesses .business-item .info-name-item .MuiChip-root {
    height: 25px;
    width: 75px;
} 

#merge-account-wrapper .merge-businesses .business-item .info-name-item .MuiChip-root.view-chip {
    border-color: #0984e3;
    background-color: #0984e3;
    color: #fff;
} 

#merge-account-wrapper .merge-businesses .business-item .info-name-item .MuiChip-root.upload-chip {
    border-color: #10ac84;
    background-color: #10ac84;
    color: #fff;
} 

#merge-account-wrapper .merge-businesses .business-item .info-name-item .MuiChip-root span {
    font-size: 0.8rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
}

#merge-account-wrapper .merge-acc-btn {
    background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#merge-account-wrapper .merge-acc-btn:hover {
    box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

/* --------------------------------------- */
/* Split merged accounts */
/* --------------------------------------- */

#split-merged-accounts {
    margin-top: 3rem;
}

#split-merged-accounts  .merged-account-card {
    padding: 16px 20px;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: box-shadow 0.3s ease;
}
  
#split-merged-accounts  .merged-account-card:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
}
  
#split-merged-accounts  .account-names {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
#split-merged-accounts .user-row {
    display: flex;
    gap: 8px;
    align-items: center;
 }
  
 #split-merged-accounts  .label {
    font-weight: 600;
    color: #1a1a1a;
    font-size: 1rem;
    font-family: 'Poppins';
}
  
#split-merged-accounts  .name {
    color: #555;
    font-size: 0.95rem;
    font-family: 'Poppins';
}
  
#split-merged-accounts  .split-btn {
    color: #333;
}

/* --------------------------------------- */
/* Split merged accounts modal */
/* --------------------------------------- */

#split-accounts-modal h3 {
    width: fit-content;
    border-bottom: 1px solid black;
    font-size: 1.7rem;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    color: rgb(19, 19, 19);
}

#split-accounts-modal h4 {
    font-family: "Poppins", serif;
  	color: rgba(0, 0, 0, 0.9);
  	font-weight: 500;
	font-size: 1.2rem;
}

#split-accounts-modal p {
    font-family: "Poppins", serif;
  	color: rgba(0, 0, 0, 0.5);
  	font-weight: 400;
	font-size: 0.9rem;
}

#split-accounts-modal .split-business-item {
    padding: 1rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#split-accounts-modal .split-business-item .info-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
}

#split-accounts-modal .split-business-item .info-item h5 {
    width: 150px;
    font-weight: 500;
	font-size: 1.1rem;
	color: rgba(0, 0, 0, 0.9);
	font-family: "Poppins", serif;
}

#split-accounts-modal .split-business-item .info-item span {
    flex-grow: 1;
	font-family: "Poppins", serif;
  	color: rgba(0, 0, 0, 0.9);
  	font-weight: 300;
	font-size: 1.1rem;
}

#split-accounts-modal .split-business-item .MuiFormControlLabel-label {
    font-weight: 500;
	font-size: 1.1rem;
	color: rgba(0, 0, 0, 0.9);
	font-family: "Poppins", serif;
}

#split-accounts-modal .split-acc-btn button {
    background-color: #1976D2;
	color: #FFFFFF !important;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#merge-account-wrapper .search-present {
    display: flex;
    justify-content: space-between;
}

#merge-account-wrapper .search-present .merge-search {
    width: 45%;
}

#merge-account-wrapper .no-search {
    display: flex;
    justify-content: flex-end;
}

#merge-account-wrapper .styled-tablist {
    background-color: #fafafa;
    border-radius: 999px;
    padding: 4px;
    gap: 8px;
    display: inline-flex;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.06);
}
  
#merge-account-wrapper .styled-tab {
    width: 180px;
    font-weight: 400;
    padding: 8px 20px;
    color: #555;
    border-radius: 999px;
    transition: background-color 0.3s, color 0.3s;
    font-family: 'Poppins';
    text-transform: capitalize;
    font-size: 16px;
}
  
#merge-account-wrapper .styled-tab.Mui-selected {
    background: linear-gradient(135deg, #1d35d0, #102194);
    color: white !important;
    font-weight: 500;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}
  
#merge-account-wrapper .styled-tab:hover {
    background-color: rgba(29, 53, 208, 0.1);
}

#merge-account-wrapper .MuiTabs-indicator {
    display: none;
}


/* --------------------------------------- */
/* Businesses Integration */
/* --------------------------------------- */


#businesses-integration-wrapper  .businesses-container {
    margin-top: 3rem;
}

#businesses-integration-wrapper .business-card {
    padding: 24px;
    border-radius: 16px;
    box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  
#businesses-integration-wrapper .business-card:hover {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}
  
#businesses-integration-wrapper .business-header {
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    margin-bottom: 16px;
}
  
#businesses-integration-wrapper .business-name {
    font-weight: 600;
    font-size: 1.1rem;
    color: #1a1a1a;
    font-family: 'Poppins';
}
  
#businesses-integration-wrapper .business-ein {
    font-size: 0.85rem;
    color: #777;
    margin-top: 4px;
    font-family: 'Poppins';
}

#businesses-integration-wrapper .business-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
#businesses-integration-wrapper .status-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#businesses-integration-wrapper .status-group.status {
    padding-bottom: 12px;
    margin-bottom: 16px;
    border-bottom: 1px solid #eee;
    
}

#businesses-integration-wrapper .status-pill {
    display: inline-block;
    padding: 4px 10px;
    border-radius: 999px;
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: capitalize;
    margin-left: 8px;
    font-family: 'Poppins';
}
  
#businesses-integration-wrapper .status-pill.active {
    background-color: #e6f4ea;
    color: #1a7f37;
}
  
#businesses-integration-wrapper .status-pill.dissolved {
    background-color: #fdecea;
    color: #d93025;
}
  
#businesses-integration-wrapper .label {
    font-weight: 400;
    color: #555;
    font-size: 0.9rem;
    font-family: 'Poppins';
}
  
#businesses-integration-wrapper .status-chip {
    background-color: #f1f1f1;
    color: #333;
    font-family: 'Poppins';
}
  
#businesses-integration-wrapper .count-chip.green {
    background-color: #e1f7e5;
    color: #219653;
    font-weight: 600;
}
  
#businesses-integration-wrapper .count-chip.red {
    background-color: #d0e9ff;
    color: #0d47a1;
    font-weight: 600;
}

#business-settings-modal .MuiPaper-root.MuiDialog-paper {
    min-height: 70vh;
}

#business-settings-modal .business-name {
    margin-bottom: 1rem;
}

#business-settings-modal .business-name h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #333;
    font-weight: 500;
    font-family: 'Poppins';
}
#business-settings-modal .business-name h2 span {
    margin-right: 0.5rem;
    border-bottom: 1px solid black;
    text-transform: capitalize;
    text-align: center;
    font-size: 1.5rem;
    color: #333;
    font-weight: 500;
    font-family: 'Poppins';
}

/* --------------------------------------- */
/* Businesses Summary */
/* --------------------------------------- */

#business-settings-modal .business-summary-wrapper {
    padding: 24px;
    /* background-color: #fafbfc; */
    border-radius: 12px;
}

#business-settings-modal .business-summary-wrapper .MuiPaper-root {
    min-height: unset;
}
  

#business-settings-modal .status-section .status-details {
    background-color: #f3f6fa;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid #dbe3ef;
}
  
#business-settings-modal .status-badge {
    font-weight: 600;
    padding: 2px 8px;
    border-radius: 6px;
    font-size: 0.9rem;
}
  
#business-settings-modal .status-badge.dissolved {
    background-color: #ffe4e4;
    color: #d32f2f;
}
  
#business-settings-modal .users-section {
    margin-top: 24px;
}
  
#business-settings-modal .users-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    background: #e3f2fd;
    padding: 8px 16px;
    border-radius: 6px;
}

#business-settings-modal .users-header button {
    padding: 11px;
    background-color: #0077c9;
    color: white;
}

#business-settings-modal .users-header button svg {
    width: 0.7em;
    height: 0.7em;
}
  
#business-settings-modal .user-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    /* border-radius: 10px; */
    border-bottom: 1px solid #e0e0e0;
    background-color: #ffffff;
    transition: box-shadow 0.3s ease;
    box-shadow: unset !important;
}
  
#business-settings-modal .user-card:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}
  
#business-settings-modal .user-name {
    font-weight: 500;
    color: #333;
}
  
#business-settings-modal .user-checkbox {
    margin-left: 0;
}

#business-settings-modal .modal-custom-tablist {
    border-bottom: 1px solid #ddd;
    display: flex;
    margin-bottom: 24px;
    margin-top: 24px;
}

#business-settings-modal .modal-custom-tablist .MuiTabs-flexContainer {
    justify-content: center;
}
  
#business-settings-modal .modal-custom-tab {
    width: 185px;
    font-weight: 400;
    padding: 8px 16px;
    border-radius: 6px 6px 0 0;
    transition: all 0.2s ease-in-out;
    color: #3d3d3d;
    text-transform: none;
    min-width: unset;
    font-size: 15px;
    font-family: 'Poppins';
}
  
#business-settings-modal .modal-custom-tab:hover {
    background-color: rgba(0, 0, 0, 0.04);
    color: #1d35d0;
}
  
#business-settings-modal .modal-custom-tab.Mui-selected {
    color: #1d35d0;
    font-weight: 500;
    border-bottom: 2px solid #1d35d0;
    background-color: #f5f8ff;
}

#business-settings-modal .modal-custom-tablist .MuiTabs-indicator {
    display: none;
}



#business-settings-modal .active-users .heading-container {
    margin-bottom: 1rem;
    background: #e3f2fd;
    padding: 8px 16px;
    border-radius: 6px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

#business-settings-modal .active-users .heading {
    font-size: 1.3rem;
    color: #333;
    font-weight: 500;
    font-family: 'Poppins';
}

.active-users #empty-data-box {
    margin-top: 3rem;
}

.active-users #empty-data-box img {
    width: 120px;
}

.active-users #empty-data-box h2 {
    font-size: 0.95rem;
}

#business-settings-modal .editing {
    font-size: 0.85rem;
    color: #858585;
    font-family: 'Poppins';
    letter-spacing: 1px;
    font-style: italic;
}

#business-settings-modal .warning {
    padding-top: 0.5rem;
}

#business-settings-modal .warning-msg {
    font-size: 0.9rem;
    color: #BF0000;
}

#business-settings-modal p {
    font-family: 'Poppins';
    color: #222;
    font-weight: 500;
    font-size: 1.1rem;
}

#business-settings-modal .new-clients-msg {
    font-weight: 400;
    color: #787777;
    font-size: 0.9rem;
    font-style: italic;
}

#business-settings-modal span,
#business-settings-modal label {
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.1rem;
}

#business-settings-modal .save-business-btn {
    background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#business-settings-modal .save-business-btn:disabled {
    background-color: #7a7a7a;
}

#business-settings-modal .add-more-btn {
    margin-top: 0.5rem;
    background-color: #0077c9;
    color: white;
}

#business-settings-modal .edit-btn {
    padding: 11px;
    background-color: #0077c9;
    color: white;
}

#business-settings-modal .edit-btn svg {
    width: 0.7em;
    height: 0.7em;
}

#business-settings-modal .business-alert {
    margin-bottom: 25px;
}

.business-alert {
    margin-bottom: 25px;
}


.search-bar {
    background-color: #fff;
    border-radius: 8px;
    transition: all 0.2s ease-in-out;
}
  
.search-bar .MuiOutlinedInput-root {
    border-radius: 8px;
    box-shadow: 0 0 0 1px #d1d5db;
    transition: box-shadow 0.3s ease;
}

.search-bar .MuiOutlinedInput-root.Mui-focused {
    box-shadow: none !important;
}
  
.search-bar .MuiInputLabel-root {
    color: #4b5563;
}
  
.search-bar .MuiInputLabel-root.Mui-focused {
    color: #333; 
}
  
.search-bar .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}
  
.search-bar .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #a0aec0; 
}




