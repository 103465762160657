#services-page {
    padding-left: 1rem;
    padding-right: 1rem;
}

#services-page .has-background {
    background-color: rgba(218, 213, 251, 0.4);
}

#services-page h1 {
    position: relative;
    width: fit-content;
    margin-left: 1rem;
    font-size: 2.8rem;
    font-family: "Playfair Display", serif;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    display: inline-block;
    line-height: 1px;
    padding: 0 10px;
}

#services-page h1::before {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    left: -15px;
    height: 3px;
    width: 20px;
    background-color: orange;
}

#services-page h1::after {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    right: -15px;
    height: 3px;
    width: 20px;
    background-color: orange;
}

#services-page h3 {
    margin-bottom: 1rem;
    font-family: "Playfair Display", serif;
    font-size: 2rem;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
}

#services-page p {
    /* width: 80%; */
    font-size: 1.1rem;
    line-height: 1.4rem;
    font-family: "Poppins", serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    position: relative;
}

#services-page ul li {
    padding: 0;
    line-height: 1.3rem;
}

#services-page ul li span {
    text-align: center;
    font-size: 1rem;
}

#services-page img {
    width: 65%;
    display: block;
    margin: 0 auto;
}

#services-page .banner-img {
    width: 100%;
}

#services-page .link-button {
    width: 100%;
    align-self: flex-end;
    margin-top: 20px !important;
    padding: 0.875rem 2rem !important;
    background-color: rgba(252, 164, 0, 1) !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    color: #fff !important;
}


/* Tax preparation page */

#tax-preparation-page h1{
    position: relative;
    width: fit-content;
    margin-left: 1rem;
    font-size: 2.8rem;
    font-family: "Playfair Display", serif;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    display: inline-block;
    line-height: 1px;
    padding: 0 10px;
}

#tax-preparation-page h1::before {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    left: -15px;
    height: 3px;
    width: 20px;
    background-color: orange;
}

#tax-preparation-page h1::after {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    right: -15px;
    height: 3px;
    width: 20px;
    background-color: orange;
}


#tax-preparation-page .tab-button {
    width: 50%;
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.3rem;
    letter-spacing: 1px;
    font-weight: 600;
}

#tax-preparation-page .MuiTabs-flexContainer {
    justify-content: center;
}

#individual-taxes p {
    text-align: center;
    font-family: "Poppins", serif;
    color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    position: relative;
}

#individual-taxes img {
    width: 70px;
}

#individual-taxes .banner-img {
    width: 100%;
}

#individual-taxes .individual-taxes-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 2px solid transparent;
    background: linear-gradient(white, white) padding-box,
    linear-gradient(45deg, purple, orange) border-box;
    border-radius: 10px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

#individual-taxes .individual-taxes-item h3 {
    padding: 0.5rem 0;
    font-size: 1.2rem;
    font-family: "Playfair Display", serif;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
}

#individual-taxes .individual-taxes-item p {
    font-size: 1rem;
}

#individual-taxes .list-container h2 {
    text-align: center;
    font-size: 2rem;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

#individual-taxes .list-container svg {
    fill: rgb(2, 152, 2);
}
#individual-taxes .list-container ul li {
    padding: 0;
}
#individual-taxes .list-container ul li span {
    font-size: 1.2rem;
    font-family: "Poppins", serif;
    color: rgba(0, 0, 0, 0.8);
}

#individual-taxes .business-list-wrapper {
    display: flex !important;
    justify-content: space-evenly !important;
}

#individual-taxes .business-list-wrapper ul {
    width: 40%;
}


/* Consulting page  */

#consulting-page h1{
    position: relative;
    width: fit-content;
    margin-left: 1rem;
    font-size: 2.8rem;
    font-family: "Playfair Display", serif;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    display: inline-block;
    line-height: 1px;
    padding: 0 10px;
}

#consulting-page h1::before {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    left: -15px;
    height: 3px;
    width: 20px;
    background-color: orange;
}

#consulting-page h1::after {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    right: -15px;
    height: 3px;
    width: 20px;
    background-color: orange;
}

#consulting-page p {
    padding-bottom: 1rem;
    text-align: left;
    font-family: "Poppins", serif;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    z-index: 999;
    position: relative;
}

#consulting-page h2 {
    text-align: center;
    font-size: 2rem;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

#consulting-page .banner-img {
    width: 100%;
}


#consulting-page .consulting-taxes-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 2px solid transparent;
    background: linear-gradient(white, white) padding-box,
    linear-gradient(45deg, purple, orange) border-box;
    border-radius: 10px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

#consulting-page .consulting-taxes-item h3 {
    margin-bottom: 1rem;
    text-align: center;
    font-family: "Playfair Display", serif;
    font-size: 1.5rem;
    line-height: 1.8rem;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
}

#consulting-page .consulting-taxes-item p {
    font-size: 1rem;
    padding-bottom: 0.5rem;
    font-weight: 400;
    text-align: center;
}

/* Other Services */

#other-services-page h1{
    position: relative;
    width: fit-content;
    margin-left: 1rem;
    font-size: 2.8rem;
    font-family: "Playfair Display", serif;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    display: inline-block;
    line-height: 1px;
    padding: 0 10px;
}

#other-services-page h1::before {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    left: -15px;
    height: 3px;
    width: 20px;
    background-color: orange;
}

#other-services-page h1::after {
    content: ' ';
    position: absolute;
    display: block;
    top: 0;
    right: -15px;
    height: 3px;
    width: 20px;
    background-color: orange;
}

#other-services-page .banner-img {
    width: 100%;
}

#other-services-page p {
    padding-bottom: 1rem;
    text-align: center;
    font-family: "Poppins", serif;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 400;
    z-index: 999;
    position: relative;
}

#other-services-page h2 {
    text-align: center;
    font-size: 2rem;
    font-family: "Playfair Display", serif;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
}

#other-services-page .bookkeeping-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 2px solid transparent;
    background: linear-gradient(white, white) padding-box,
    linear-gradient(45deg, purple, orange) border-box;
    border-radius: 10px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

#other-services-page .bookkeeping-item-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border: 2px solid transparent;
    background: linear-gradient(white, white) padding-box,
    linear-gradient(45deg, purple, orange) border-box;
    border-radius: 10px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

#other-services-page .bookkeeping-item h3 {
    margin-bottom: 1rem;
    font-family: "Playfair Display", serif;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
}

#other-services-page .bookkeeping-item p {
    font-size: 1rem;
    padding-bottom: 0.5rem;
    font-weight: 400;
    text-align: center;
}

#other-services-page .bookkeeping-item-2 h3 {
    margin-bottom: 1rem;
    font-family: "Playfair Display", serif;
    font-size: 1.7rem;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
}

#other-services-page .bookkeeping-item-2 p {
    font-size: 2rem;
    padding-bottom: 0.5rem;
    font-weight: 500;
    text-align: center;
}

#other-services-page .representation-container {
    background-color: rgba(218, 213, 251, 0.4);
    padding: 100px 0;
}

/* .intro-wrapper {
    background-color: rgb(227, 230, 247);
    border-radius: 5px;
    padding: 3rem 1rem;
} */

.intro-wrapper {
    margin-top: -100px !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 123px 0px 120px 0px;
    position: relative;
    overflow: hidden;
  }
  
  .intro-wrapper:after {
    content: '';
    background-image: url(../assets/homepage/slider-left-dec.jpg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 60px;
    width: 262px;
    height: 625px;
    z-index: 1;
  }
  
  .intro-wrapper:before {
    content: '';
    background-image: url(../assets/homepage/slider-right-dec.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    right: 0;
    top: 60px;
    width: 1159px;
    height: 797px;
    z-index: -1;
  }