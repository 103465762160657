#contact-us-page .MuiContainer-root {
    max-width: 1320px;
}

#contact-us-page h1 {
    width: fit-content;
    display: inline-block;
    position: relative;
    font-size: 3rem;
    font-family: "Poppins";
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    z-index: 1;
  }
  
#contact-us-page h1::after {
    background: rgba(155,34,252,0.14);
    background: linear-gradient(90deg, rgba(155,34,252,0.14) 0%, rgba(98,98,210,0.14) 47%, rgba(27,96,250,0.14) 100%);
    bottom: -10%;
    right: -8px;
    content: ' ';
    display: inline-block;
    height: 60%;
    width: 100%;
    margin-top: 10px;
    margin-left: -5px;
    margin-right: -3px;
    position: absolute;
    z-index: 1;
}

#contact-us-page .contact-form-container {
    position: relative;
}

#contact-us-page .contact-form-container::before {
    content: '';
    background-image: url(../assets/left-background.jpg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 650px;
    z-index: -1;
}

#contact-us-page .error-msg {
    text-align: center;
    padding-bottom: 1rem;
    color: #BF0000;
    font-family: "Poppins";
    font-size: 1.1rem;
}

#contact-us-page .success-msg {
    text-align: center;
    padding-bottom: 1rem;
    color: #077701;
    font-family: "Poppins";
    font-size: 1.1rem;
}

#contact-us-page .contact-form-container img {
    width: 100%;
}

#contact-us-page .contact-form-container h3 {
    text-align: center;
    padding-bottom: 1.1rem;
    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 500;
}

#contact-us-page .contact-submit-btn {
    text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Poppins';
    background-color: #1976D2;
	border: 1px solid #1976D2;
    color: #ffffff; 
	padding: 7px 35px;
	border-radius: 25px;
    transition: all 0.3s ease;
}

#contact-us-page .contact-submit-btn:hover  {
    box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

.contact-grid-wrapper {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.contact-grid-wrapper .item {
    width: 25%;
}

.contact-grid-wrapper .contact-grid-item {
    height: 100%;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.196);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: rgba(255, 255, 255, 0.7);
}

.contact-grid-wrapper .contact-grid-item img {
    display: block;
    margin: 0 auto;
    width: 60px;
}

.contact-grid-wrapper .contact-grid-item p {
    text-align: center;
    padding-top: 1rem;
    font-size: 1.1rem;
    font-family: "Poppins";
    color: #333;
    font-weight: 500;
}

@media (max-width: 920px) {
    .contact-grid-wrapper .item {
        width: 33%;
    }

    .contact-grid-wrapper .contact-grid-item img {
        width: 50px;
    }

    .contact-grid-wrapper .contact-grid-item p {
        font-size: 1rem;
    }
    
}

@media (max-width: 820px) {

    #contact-us-page .form-grid {
        flex-direction: column;
        align-items: center;
        row-gap: 3rem;
    }

    #contact-us-page .form-grid .MuiGrid-item {
        max-width: 90%;
    }

    
}

@media (max-width: 620px) {

    #contact-us-page .form-grid .MuiGrid-item {
        max-width: 100%;
    }
    .contact-grid-wrapper .item {
        width: 85%;
    }

    .contact-grid-wrapper .contact-grid-item img {
        width: 60px;
    }

    .contact-grid-wrapper .contact-grid-item p {
        font-size: 1.1rem;
    }

    #contact-us-page .contact-grid-container {
        flex-direction: column;
        align-items: center;
    }
    
}