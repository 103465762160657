@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;400&display=swap");

* {
  	box-sizing: border-box;
  	margin: 0;
  	padding: 0;
}

body {
	/* font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	/* background-color: #29359314; */
	/* background-size: contain;
	background-repeat: no-repeat;
	position: relative;
	color: #111111;
	background-image: url("./assets/homepage/fff.png");
	background-position: -20% 0%;
	overflow: visible;
	background-size: 70% 18%; */
}

#main {
	/* max-width: 1600px; */
	min-height: calc(100vh - 100px);
	height: calc(100% - 100px);
	max-width: 1536px;
	margin: 0 auto;
	padding: 0 1rem;
}

footer {
    background-image: url(./assets/footer-bg-v2.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 130px;
}

footer h6{
    text-align: center;
	color: #fff !important;
	font-family: "Poppins";
	font-weight: 500 !important;
	font-size: 1.1rem !important;
}

footer p{
    text-align: center;
	color: #fff !important;
	font-family: "Poppins";
	font-weight: 500 !important;
	font-size: 1.1rem !important;
}


/* --------------------------------------- */
/* Header */
/* --------------------------------------- */

header {
	position: relative !important;
	width: 95% !important;
	margin: 0 auto;
	background-color: transparent !important;
	box-shadow: none !important;
	z-index: 999;
	padding: 1rem 0;
	/* background-size: 100% 100% !important; */
	animation: 8s ease-in-out infinite !important;
}

header .main-menu-wrapper {
	justify-content: center;
	column-gap: 1rem;
}

header .main-menu-wrapper .MuiButtonBase-root {
	text-transform: capitalize;
	font-size: 1rem;
	font-family: Poppins;
	border-radius: 0;
}

header .main-menu-wrapper .MuiButtonBase-root:hover {
	background-color: #1565c018;

}

header .main-menu-wrapper .MuiButtonBase-root.active {
	border-bottom: 2px solid #293693;
}

header .user-button {
	/* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	border: 2px solid #293693;
	text-transform: capitalize;
	font-size: 1rem;
	font-family: Poppins;
	transition: transform 0.5s ease;
}

header .user-button:hover {
	border: 2px solid #293693;
	background-color: #FFFFFF;
	transform: scale(1.02);
}

.personal-menu a, 
.personal-menu li,
.personal-menu p {
	column-gap: 1rem;
	color: #333;
	font-family: 'Poppins';
	font-size: 1.1rem;
	font-weight: 400;
}


.desktop-logo {
  	width: 170px;
}

.mobile-logo{
  	width: 80px;
}

header button .MuiSvgIcon-root {
	width: 2rem;
	height: 2rem;
	fill: #000;
}

header button .MuiAvatar-root {
	background-color: transparent;
	border: 2px solid #293693;
	color: #293693;
}




/* --------------------------------------- */
/* Alert Message */
/* --------------------------------------- */

.alert-message-block {
	position: fixed;
	bottom: 2rem;
	right: 0.5rem;
	width: fit-content;
	min-width: 300px;
}

.alert-message-block.alert-1 {
	bottom: 6rem;
}

.alert-message-block.alert-2 {
	bottom: 9rem;
}

.alert-massage-hidden {
  	display: none !important;
}

.modal-alert-message-block {
	position: absolute;
	top: 0.5rem;
	left: 0;
	width: fit-content;
}


/* --------------------------------------- */
/* Send Invite View */
/* --------------------------------------- */

.invitation-container {
	width: 100%;
	/* max-width: 550px; */
}

.invitation-form-container .send-invite-btn {
	margin-left: auto;
    display: block;
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;

}

.invitation-form-container .send-invite-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

.invitation-container img {
	display: block;
	width: 70%;
	/* margin: 0 auto; */

}

.invitation-container .discount-info p {
	font-size: 1rem;
	font-family: "Poppins";
	color: #333;
	font-weight: 400;
}

/* --------------------------------------- */
/* Empty Data View*/
/* --------------------------------------- */

#empty-data-box, #error-data-box {
	margin-top: 4rem;
	margin-bottom: 3rem;
}

#empty-data-box img {
	width: 300px;
}

#empty-data-box h2, #error-data-box h2 {
	width: 80%;
	margin: 0 auto;
	text-align: center;
	font-size: 1.2rem;
	font-family: "Poppins";
	color: #333;
	font-weight: 500;
}

/* --------------------------------------- */
/* 404 View*/
/* --------------------------------------- */

#not-found-box {
	margin-top: 4rem;
	margin-bottom: 3rem;
}

#not-found-box img {
	width: 400px;
}

#not-found-box h1 {
	margin: 1rem auto;
	text-align: center;
	font-size: 1.5rem;
	font-family: "Poppins";
	color: #333;
	font-weight: 500;
}

/* --------------------------------------- */
/* Unauthorized View*/
/* --------------------------------------- */

#unauthorized-box {
	margin-top: 4rem;
	margin-bottom: 3rem;
}

#unauthorized-box img {
	width: 500px;
}

#unauthorized-box h1 {
	margin: 1rem auto;
	text-align: center;
	font-size: 2rem;
	font-family: "Poppins";
	color: #333;
	font-weight: 600;
}

#unauthorized-box p {
	width: 80%;
	margin: 1rem auto;
	text-align: center;
	font-size: 1.2em;
	font-family: "Poppins";
	color: #333;
	font-weight: 400;
}

#unauthorized-box a {
	display: block;
	width: 200px;
    margin: 0 auto;
	padding: 8px 40px;
    text-align: center;
	background: #fff;
	color: #1704d1;
	border: 1px solid #1704d1;
	text-transform: capitalize;
	text-decoration: none;
	font-family: 'Poppins';
	font-size: 1.3rem;
	font-weight: 500;
	border-radius: 31px;
	transition: all 0.3s ease;
}

#unauthorized-box a:hover {
	color: #FBFBFF;
	background: #1704d1;
}

/* responsive styles */
/* md */
@media screen and (max-width: 768px) {
  
}

/* lg */
 @media screen and (min-width: 769px) and (max-width: 1170px) {
   
 }

/* xl */
 @media screen and (min-width: 1171px) {
 
 }

