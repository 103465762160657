#about-us-page .MuiContainer-root {
    max-width: 1320px;
}

#about-us-page h1 {
    width: fit-content;
    display: inline-block;
    position: relative;
    font-size: 3rem;
    font-family: "Poppins";
    color: rgba(0, 0, 0, 0.8);
    font-weight: 600;
    z-index: 1;
}
  
#about-us-page h1::after {
    background: rgba(155,34,252,0.14);
    background: linear-gradient(90deg, rgba(155,34,252,0.14) 0%, rgba(98,98,210,0.14) 47%, rgba(27,96,250,0.14) 100%);
    bottom: -10%;
    right: -8px;
    content: ' ';
    display: inline-block;
    height: 60%;
    width: 100%;
    margin-top: 10px;
    margin-left: -5px;
    margin-right: -3px;
    position: absolute;
    z-index: 1;
}

#about-us-page {
    margin-top: 5rem;
}

#about-us-page .grid-section {
    justify-content: space-between;
    align-items: center;
}

#about-us-page .grid-section .intro-content {
    width: 40%;
}

#about-us-page .grid-section .img-content {
    width: 50%;
}

#about-us-page .grid-section .img-content img {
    width: 100%;
}

#about-us-page .grid-section .intro-content h2 {
    text-align: left;
    font-family: "Poppins";
    font-size: 4.5rem;
    line-height: 4rem;
    font-weight: 600;
    color: #2e2c2c;
}

#about-us-page .grid-section .intro-content .tax-box {
    margin-top: -1rem;
    display: flex;
    justify-content: flex-end;
}

#about-us-page .grid-section .intro-content .tax-box span:nth-child(1) {
    font-family: "Poppins";
    font-size: 5rem;
    font-weight: 700;
    color: rgb(242,105,33);
}

#about-us-page .grid-section .intro-content .tax-box span:nth-child(2) {
    font-family: "Poppins";
    font-size: 5rem;
    font-weight: 700;
    color: rgb(140,58,147);
}

#about-us-page .grid-section .intro-content p {
    margin-bottom: 1.5rem;
    /* text-align: center; */
    font-family: "Poppins";
    font-size: 1.1rem;
    font-weight: 400;
    color: #333;
}

#about-us-page .grid-section .intro-content .learn-more-btn {
    font-family: "Poppins";
    text-transform: capitalize;
    background-color: rgb(242,105,33);
    color: #fff;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 10px 25px;
}

#about-us-page .our-mission-section-intro {
    margin-top: 5rem;
}

#about-us-page .our-mission-section-intro h2 {
    margin-bottom: 2rem;
    text-align: center;
    font-family: "Poppins";
    font-size: 3.5rem;
    line-height: 3rem;
    font-weight: 600;
    color: #2e2c2c;
}

#about-us-page .our-mission-section-intro p:nth-child(2) {
    width: 70%;
    border-radius: 12px;
    text-align: center;
    padding: 1rem 0.5rem;
    margin: 0 auto 1rem auto;
    font-family: "Poppins";
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 500;
    background: rgb(140,58,147);
    color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#about-us-page .our-mission-section-intro p:nth-child(3) {
    margin-bottom: 1rem;
    text-align: center;
    font-family: "Poppins";
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 500;
    color: #2e2c2c;
}

#about-us-page .our-mission-section {
    margin-top: 5rem;
    position: relative;
}

#about-us-page .our-mission-section::before {
    content: '';
    background-image: url(../assets/left-background.jpg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    height: 650px;
    z-index: -1;
}

#about-us-page .our-mission-section .grid-container {
    justify-content: space-between;
    align-items: center;
}

#about-us-page .our-mission-section .grid-container .content-wrapper {
    width: 40%;
}

#about-us-page .our-mission-section .items-wrapper {
    width: 55%;
}

#about-us-page .our-mission-section .grid-container .content-wrapper h2 {
    margin-bottom: 3rem;
    text-align: center;
    font-family: "Poppins";
    font-size: 3.5rem;
    line-height: 3rem;
    font-weight: 600;
    color: #2e2c2c;
}



#about-us-page .our-mission-section .items-wrapper {
    column-gap: 2rem;
}

#about-us-page .our-mission-section .items-wrapper .first-column,
#about-us-page .our-mission-section .items-wrapper .second-column {
    width: 45%;
    row-gap: 2rem;
}

#about-us-page .our-mission-section .items-wrapper .first-column {
    margin-top: 5rem;
}

#about-us-page .our-mission-section .items-wrapper .item {
    padding: 2.5rem 1.5rem;
    height: 350px;
    /* background-color: aliceblue; */
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#about-us-page .our-mission-section .items-wrapper .first-column .item:nth-child(1),
#about-us-page .our-mission-section .items-wrapper .second-column .item:nth-child(2){
    background-color: rgba(242, 106, 33, 0.3);
}

#about-us-page .our-mission-section .items-wrapper .first-column .item:nth-child(2),
#about-us-page .our-mission-section .items-wrapper .second-column .item:nth-child(1){
    background-color: rgba(139, 58, 147, 0.2);
}

#about-us-page .our-mission-section .items-wrapper .item img {
   margin-bottom: 2rem;
   background: #fff;
   border-radius: 50%;
}

#about-us-page .our-mission-section .items-wrapper .item h4 {
    margin-bottom: 1rem;
    font-family: "Poppins";
    font-size: 1.4rem;
    line-height: 1.5rem;
    font-weight: 600;
    color: #2e2c2c;
}

#about-us-page .our-mission-section .items-wrapper .item p {
    margin-bottom: 1rem;
    font-family: "Poppins";
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: #2e2c2c;
}

#about-us-page .quote-container {
    background-image: url(../assets/homepage/slider-right-dec.jpg);
    /* background-image: url(../assets/homepage/quote-bg-v3.jpg); */
    background-repeat: unset;
    background-position: center center;
    background-size: auto;
    padding: 80px 0px;
    text-align: center;
    position: relative;
    z-index: 2;
    margin-top: 5rem;
}

#about-us-page .quote-container p {
    color: #333;
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-family: "Poppins";
    font-weight: 500;
}

#about-us-page .quote-container p:nth-child(2) {
    color: rgb(140,58,147);
    margin-bottom: 1rem;
    font-size: 2.7rem;
    font-family: "Poppins";
    font-weight: 700;
}

@media (max-width: 1040px) {

    #about-us-page .grid-section .intro-content {
        width: 40%;
    }

    #about-us-page .grid-section .intro-content h2 {
        font-size: 4rem;
    }

    #about-us-page .grid-section .intro-content .tax-box span {
        font-size: 4rem !important;
    }

    #about-us-page .grid-section .intro-content p {
        font-size: 1rem;
    }

    #about-us-page .our-mission-section .grid-container .content-wrapper {
        width: 100%;
        margin-bottom: 1rem;
    }

    #about-us-page .our-mission-section .grid-container .content-wrapper h2 {
        margin-bottom: 2rem;
    }

    #about-us-page .our-mission-section .grid-container .content-wrapper p:nth-child(2) {
        width: 80%;
        margin: 1.5rem auto;
    }

    #about-us-page .our-mission-section .items-wrapper {
        width: 100%;
        justify-content: center;
    }

    #about-us-page .quote-container p {
        font-size: 1.3rem;
    }

}


