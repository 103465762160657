/* --------------------------------------- */
/* Business Prompt Dialog */
/* --------------------------------------- */

#business-prompt-dialog p {
    text-align: center;
    font-size: 1.2rem;
    font-family: "Poppins";
    color: #333;
    font-weight: 500;
}

#business-prompt-dialog .cancel-button {
    max-width: 185px;
	width: 185px;
	background-color: transparent;
	border: 1px solid #4b4b4b74;
	color: #4B4B4B; 
	padding: 8px 5px;
	border-radius: 25px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;
}

#business-prompt-dialog .cancel-button:hover {
	background-color: #4B4B4B;
	color: white;
	border: 1px solid #4B4B4B; 
}

#business-prompt-dialog .add-button {
    max-width: 185px;
	width: 185px;
	background-color: #0530ca;
	border: 1px solid #0530ca;
	color: #ffffff; 
	padding: 8px 5px;
	border-radius: 25px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;
}

#business-prompt-dialog .add-button:hover {
	background-color: #001d87;
	color: white;
	border: 1px solid #001d87; 
}

/* --------------------------------------- */
/* Engagement letter */
/* --------------------------------------- */

.engagement-letter-container .letter-header h5 {
    font-size: 1.4rem;
    font-weight: 500;
    color: #333;
    font-family: "Poppins";
}

.engagement-letter-container .letter-content p {
    font-size: 1rem;
    color: #333;
    font-family: "Poppins";
}

.engagement-letter-container .save-engagement-btn,
.engagement-letter-container .sign-button {
    background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

.engagement-letter-container .save-engagement-btn:hover,
.engagement-letter-container .sign-button:hover {
    box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

.signature-img-container img {
    max-width: 255px;
}

/* --------------------------------------- */
/* Signature Pad */
/* --------------------------------------- */

#signature-modal p {
    font-size: 1.4rem;
    font-family: "Poppins";
    color: #333;
    font-weight: 500;
}

#signature-modal .cancel-btn,
#signature-modal .clear-btn  {
    text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Poppins';
}

#signature-modal .cancel-btn:hover {
    text-decoration: underline;
}

#signature-modal .done-btn {
    text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Poppins';
    background-color: #1976D2;
	border: 1px solid #1976D2;
    color: #ffffff; 
	padding: 5px 25px;
	border-radius: 25px;
    transition: all 0.3s ease;
}

#signature-modal .done-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

#signature-modal .canvas-container {
    width: 90%;
    margin: 0 auto;
    border-bottom: 1px solid #868686;
}

#signature-modal .canvas-container canvas {
    width: 100%;
    min-height: 200px;
}

/* --------------------------------------- */
/* Business Application Form */
/* --------------------------------------- */

#business-application-form .skip-business-application {
    text-transform: unset;
    font-size: 1.1rem;
    font-family: "Poppins";
    border: 2px #3505b8 solid;
    width: 150px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#business-application-form .business-input-set {
    position: relative;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    background: #fff;
    padding: 1rem;
    border-radius: 12px;
}

#business-application-form .business-input-set .delete-business-set-btn {
    position: absolute;
    top: 0;
    right: -40px;
}

#business-application-form .business-input-set .delete-business-set-btn svg{
    fill: #BF0000;
}

#business-application-form .add-business-set-btn {
    border-radius: 50%;
    min-width: 50px;
    width: 50px;
    height: 50px;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 500;
    background-color: #1976D2;
    transition: all 0.3s ease;
}

#business-application-form .add-business-set-btn:hover  {
    box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

#business-application-form .submit-business-application-btn {
    text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Poppins';
    background-color: #1976D2;
	border: 1px solid #1976D2;
    color: #ffffff; 
	padding: 5px 25px;
	border-radius: 25px;
    transition: all 0.3s ease;
}

#business-application-form .submit-business-application-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

#business-application-wrapper .cancel-business-application {
    text-transform: unset;
    font-size: 1.1rem;
    font-family: "Poppins";
    border: 2px #3505b8 solid;
    width: 200px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#business-application-form .note-wrapper p {
    font-size: 1rem;
    line-height: 1rem;
    font-family: "Poppins";
    color: #333;
}

/* --------------------------------------- */
/* Business Application Success Message  */
/* --------------------------------------- */

#success-application-wrapper {
    height: 80vh;
}
#success-application-wrapper img {
    width: 100%;
}

#success-application-wrapper h2 {
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
    font-family: 'Poppins';
    color: #333;
}

#success-application-wrapper p {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    color: #333;
    font-family: 'Poppins';
    font-size: 1.2rem;

}

#success-application-wrapper button {
    text-transform: uppercase;
    font-weight: 600;
    background: #3505b8;
    color: #fff;
    padding: 10px 20px;
    letter-spacing: 1px;
}
#success-application-wrapper button:hover {
    opacity: 0.8;
}

/* --------------------------------------- */
/* Client Application Client Type  */
/* --------------------------------------- */

#client-application-wrapper {
    min-height: 80vh;
    position: relative;
}

#client-application-wrapper .client-type-app-wrapper {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 60%;
    transform: translate(-50%, -40%);
}

.client-type-app-wrapper h4 {
    text-align: center;
    font-size: 2rem;
    font-family: 'Poppins';
    color: #333;
    font-weight: 500;
}

#client-application-wrapper .client-type-button-container {
    width: 100%;
    margin: 3rem auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

#client-application-wrapper .client-type-button {
    width: 240px;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    margin: 5px;
    text-transform: capitalize;
    font-family: 'Poppins';
}

#client-application-wrapper .client-type-button:hover {
    transform: translateY(-3px); 
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); 
}


#client-application-wrapper .client-type-button.personal {
    background-color: blue;
    color: white; 
}

#client-application-wrapper .client-type-button.personal:hover {
    background-color: #003366; 
}

#client-application-wrapper .client-type-button.business {
    background-color: purple;
    color: white; 
}

#client-application-wrapper .client-type-button.business:hover {
    background-color: #4B0082; 
}

#client-application-wrapper .client-type-button.both {
    background-color: orange;
    color: white;
}

#client-application-wrapper .client-type-button.both:hover {
    background-color: #FF4500;
}

/* --------------------------------------- */
/* Client Application Document Upload */
/* --------------------------------------- */

#documents-upload-application-wrapper {
    margin-top: 2rem;
    height: 80vh;
}

#documents-upload-application-wrapper .note-wrapper p {
    font-size: 1rem;
    line-height: 1rem;
    font-family: "Poppins";
    color: #333;
}

#documents-upload-application-wrapper .skip-documents-upload {
    text-transform: unset;
    font-size: 1.1rem;
    font-family: "Poppins";
    border: 2px #3505b8 solid;
    width: 150px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#documents-upload-application-wrapper .drop-zone-wrapper {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 5px;
    background: #fff;
    padding: 1rem;
}

#documents-upload-application-wrapper .dropZone {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    padding: 3rem 0;
    border: 1px dashed rgb(45,168,253);
    border-radius: 12px;
    background: rgb(34,193,195);
    background: linear-gradient(0deg, rgba(34,193,195,0) 0%, rgba(45,168,253,0.3) 100%);
}

#documents-upload-application-wrapper .dropZone .hidden-file {
    display: none;
}

#documents-upload-application-wrapper .dropZone-content {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}

#documents-upload-application-wrapper .dropZone-content p {
    font-family: "Poppins";
    color: #333;
}

#documents-upload-application-wrapper .dropZone-content img {
    width: 60px;
}

#documents-upload-application-wrapper .dropZone-content button {
    width: 60%;
    background-color: rgb(0, 140, 255) !important;
    color: #fff;
    padding: 0.5rem!important;
    font-size: 1rem !important;
    text-transform: capitalize;
    font-family: "Poppins";
    font-weight: 500;
}

#documents-upload-application-wrapper .application-uploaded-file {
	margin-bottom: 0.5rem;
	border: #989898 1px solid;
	border-radius: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#documents-upload-application-wrapper .application-uploaded-file span{
	font-size: 0.8rem;
	font-weight: 500;
	font-family: "Poppins";
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#documents-upload-application-wrapper .application-uploaded-file .file-icon {
  	width: 30px;
}

#documents-upload-application-wrapper .application-preview-files ul {
    width: 90%;
    margin: 0 auto;
    max-height: 230px;
    overflow-y: auto;
}

#documents-upload-application-wrapper .application-preview-files ul li {
    width: 98%;
}

#documents-upload-application-wrapper .submit-files-button {
    text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Poppins';
    background-color: #1976D2;
	border: 1px solid #1976D2;
    color: #ffffff; 
	padding: 5px 25px;
	border-radius: 25px;
    transition: all 0.3s ease;
}

#documents-upload-application-wrapper .notes-container {
    margin-top: -1rem;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 5px;
    background: #fff;
    padding: 1rem;
}

#documents-upload-application-wrapper .notes-container h4 {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-weight: 500;
	font-family: "Poppins";
    color: #333;
}

#documents-upload-application-wrapper .notes-container p {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 400;
	font-family: "Poppins";
    color: #444;
}

/* --------------------------------------- */
/* Skip Upload Documents */
/* --------------------------------------- */

#skip-documents-box {
    margin-top: 4rem;
	margin-bottom: 3rem;
}

#skip-documents-box img {
    width: 400px;
}

#skip-documents-box h2 {
	width: 75%;
	margin: 0 auto;
	text-align: center;
	font-size: 1.1rem;
	font-family: "Poppins";
	color: #333;
	font-weight: 500;
}

#skip-documents-box p {
	width: 75%;
	margin: 0 auto;
	text-align: center;
	font-size: 1.1rem;
	font-family: "Poppins";
	color: #333;
	font-weight: 500;
}

#skip-documents-box .finish-application-btn {
    text-transform: capitalize;
    display: block;
    margin: 1rem auto;
    padding: 10px 20px;
    background: purple;
    color: #fff;
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: 500;

}

#skip-documents-box .note-msg {
    margin-top: 2rem;
    font-size: 1rem;
    font-weight: 400;
    opacity: 0.9;
}

/* --------------------------------------- */
/* Client Application Personal Questionnaire */
/* --------------------------------------- */


#personal-questionnaire-form .group-heading {
    font-family: Poppins !important;
    font-size: 1.3rem !important;
    letter-spacing: 1px;
    font-weight: 600 !important;
    color: rgb(84, 84, 84);
    margin-bottom: 1.5rem !important;
    background: #0a54d41f;
    padding: 0.5rem;
}

#personal-questionnaire-form label {
    font-size: 1.1rem !important;
    font-weight: 500;
    font-family: 'Poppins';
    color: #131313;
    margin-bottom: 0.5rem !important;
    /* text-transform: capitalize; */
}

#personal-questionnaire-form label .MuiChip-root {
    height: 26px;
    margin-left: 0.5rem;
}

#personal-questionnaire-form .MuiInputBase-root {
    width: 70%;
}

#personal-questionnaire-form .questionnaire-note {
    color: #BF0000;
    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 500;
}

#personal-questionnaire-form .sub-question {
    margin-left: 2rem;
}

#personal-questionnaire-form .submit-questionnaire-btn {
    text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Poppins';
    background-color: #1976D2;
	border: 1px solid #1976D2;
    color: #ffffff; 
	padding: 7px 35px;
	border-radius: 25px;
    transition: all 0.3s ease;
}

#personal-questionnaire-form .submit-questionnaire-btn:hover  {
    box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

/* --------------------------------------- */
/* Unanswered Questions Modal */
/* --------------------------------------- */
    
#unanswered-questions-modal h5 {
    margin-bottom: 1rem;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
    font-family: 'Poppins';
    color: #333;
}

#unanswered-questions-modal h6 {
    font-family: Poppins !important;
    font-size: 1.1rem !important;
    letter-spacing: 1px;
    font-weight: 600 !important;
    color: rgb(84, 84, 84);
    margin: 1rem 0 !important;
    background: #0a54d41f;
    padding: 0.5rem;
}

#unanswered-questions-modal p {
    font-family: Poppins !important;
    font-size: 1rem !important;
    color: #000;
    text-transform: capitalize;
}


