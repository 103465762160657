@keyframes slideInFromLeft {
	0% {
	  transform: translateX(-100%);
	  opacity: 0;
	}
	100% {
	  transform: translateX(0);
	  opacity: 1;
	}
}
  
@keyframes slideInFromRight {
	0% {
	  transform: translateX(100%);
	  opacity: 0;
	}
	100% {
	  transform: translateX(0);
	  opacity: 1;
	}
}

@keyframes appearFromSmall {
	from {
	  transform: scale(0); 
	  opacity: 0; 
	}
	to {
	  transform: scale(1); 
	  opacity: 1; 
	}
  }

/* --------------------------------------- */
/* Register / Sign Ups  */
/* --------------------------------------- */

#register-screen {
 	margin-top: 6rem;
	margin-bottom: 2rem;
}

#register-screen h1 {
  	width: fit-content;
	display: inline-block;
	position: relative;
	font-size: 3rem;
	font-family: "Poppins";
	color: rgba(0, 0, 0, 0.8);
	font-weight: 600;
}

#register-screen h1::after {
  	background: rgba(155,34,252,0.14);
	background: linear-gradient(90deg, rgba(155,34,252,0.14) 0%, rgba(98,98,210,0.14) 47%, rgba(27,96,250,0.14) 100%);
	bottom: -10%;
	right: -8px;
	content: ' ';
	display: inline-block;
	height: 60%;
	width: 100%;
	margin-top: 10px;
	margin-left: -5px;
	margin-right: -3px;
	position: absolute;

}

#register-screen h3 {
	font-size: 1.4rem;
	font-family: "Poppins";
	color: rgba(0, 0, 0, 0.7);
	font-weight: 600;
}

#register-screen .additional-input p {
	font-family: 'Poppins';
    color: #333;
}

#register-screen .additional-input .personal-error {
	font-family: "Poppins";
    font-size: 0.8rem;
    line-height: 1;
    color: #d32f2f;
}

#register-screen .additional-input p.Mui-error {
    color: #d32f2f;
}
#register-screen .note-msg {
	margin-top: 1rem;
	border: 2px solid #1976D2;
    padding: 0.5rem;
    border-radius: 6px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
	
}

#register-screen .note-msg p {
	margin-bottom: 0.5rem;
	font-size: 0.9rem;
	font-family: 'Poppins';
    color: #333;
	font-weight: 500;
}

#register-screen .grid-container .image-container,
#register-screen .grid-container .form-container {
  	width: 40%;
	will-change: transform, opacity;
}

#register-screen .grid-container .image-container {
	animation: slideInFromLeft 1s forwards;
}

#register-screen .grid-container .form-container {
	animation: slideInFromRight 1.5s forwards;
}

#register-screen .grid-container .image-container img {
  	width: 100%;
}

#register-screen .grid-container .form-container p.Mui-error {
	font-family: "Poppins";
    font-size: 0.8rem;
    line-height: 1;
}

#register-screen .grid-container .form-container .MuiFormLabel-root {
	font-family: 'Poppins';
    color: #333;
}

#register-screen .grid-container .form-container .MuiFormLabel-root.Mui-error {
    color: #d32f2f;
}

#register-screen .auth-button {
	text-transform: capitalize;
    font-size: 1.1rem;
    font-family: 'Poppins';
    background-color: #1976D2;
	border: 1px solid #1976D2;
    color: #ffffff; 
	padding: 7px 35px;
	border-radius: 25px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: all 0.3s ease;
}

#register-screen .auth-button:hover  {
    box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

@media (max-width:1040px) {

	#register-screen {
		margin-top: 4rem;
		margin-bottom: 4rem;
	}

	#register-screen .grid-container .image-container {
		width: 35%;
	}

	#register-screen .grid-container .form-container {
		width: 50%;
	}

}

@media (max-width: 768px) {

	#register-screen {
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
	}

	#register-screen .grid-container .image-container {
		display: none;
	}

	#register-screen .grid-container .form-container {
		width: 100%;
	}

}


/* --------------------------------------- */
/* Login / OTP  */
/* --------------------------------------- */

#login-screen {
	margin-top: 6rem;
	position: relative;
}

#login-screen::before {
    content: '';
    background-image: url(../../assets/left-background.jpg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: -122px;
    width: 100%;
    height: 650px;
    z-index: -1;
}

#login-screen .grid-container {
	justify-content: space-around;
}

#login-screen .grid-container .image-container,
#login-screen .grid-container .form-container {
  	width: 40%;
	will-change: transform, opacity;
}

#login-screen .grid-container .image-container {
	animation: slideInFromLeft 1s forwards;
}

#login-screen .grid-container .form-container {
	animation: slideInFromRight 1s forwards;
}

#login-screen .grid-container .image-container img {
	width: 100%;
}

#login-screen .grid-container .image-container .otp-img {
	animation: slideInFromLeft 1s forwards;
}

#login-screen .form-wrapper {
	width: 100%;
    background: linear-gradient(180deg, rgb(235, 235, 249) 10%, rgb(186, 187, 252) 100%);
    border-radius: 12px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
	padding: 1rem;
}

#login-screen .form-wrapper .form-header {
	justify-content: center;
}

#login-screen .form-wrapper .form-header .login-img {
	background-color: #ffffffa1;
    padding: 2rem;
    border-radius: 50%;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

#login-screen .form-wrapper .form-header .login-img img {
	width: 100px;
}

#login-screen .form-wrapper .form-header h1 {
	text-align: center;
    font-size: 2.5rem;
    font-family: 'Poppins';
    font-weight: 600;
    color: #4d4d4d;
}

#login-screen .form-wrapper .form-header p {
	margin-bottom: 0.5rem;
	text-align: center;
    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 500;
    color: #4d4d4db4;
}

#login-screen .form-wrapper .form-header span {
	display: block;
    color: #1704d1;
}

#login-screen .form-wrapper .form-header small {
    color: #1704d1;
	font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 500;
}

#login-screen .login-form {
	padding: 0 2rem;
	animation: slideInFromRight 1s forwards;
	will-change: transform, opacity;
}

#login-screen .login-form .MuiInputAdornment-root {
	z-index: 999;
}

#login-screen .login-form input {
	padding: 14px;
	border-radius: 27px;
	z-index: 999;
}

#login-screen .login-form input:-webkit-autofill {
	border-radius: 27px;
}

#login-screen .login-form fieldset {
	background-color: #fff;
	border: none;
	border-radius: 27px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

#login-screen .login-form input::placeholder {
	font-family: 'Poppins';
    font-weight: 400;
}

#login-screen .login-form .login-button {
	background: #fff;
	color: #1704d1;
	text-transform: capitalize;
	font-family: 'Poppins';
	font-size: 1.3rem;
	font-weight: 500;
	padding: 8px 40px;
	border-radius: 31px;
	transition: all 0.3s ease;
}

#login-screen .login-form .login-button:hover {
	color: #FBFBFF;
	background: #0d026ca0;
} 

#login-screen .forgot-pass-link a {
	color: #1704d1;
	font-family: 'Poppins';
	font-size: 1rem;
}

#login-screen .login-error-container .MuiGrid-item {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 0.5rem;
	padding: 0.5rem;
	border-radius: 5px;
	background-color: #d63031;
	animation: slideInFromRight 1s forwards;
	will-change: transform, opacity;
	
}

#login-screen .login-error-container .MuiGrid-item p{
	font-family: 'Poppins';
	font-size: 1rem;
	color: #fff;
	font-weight: 500;
}

#login-screen .login-error-container .MuiGrid-item svg {
	fill: #fff;
}

#login-screen .login-error-container .MuiGrid-item button {
	padding: 0;
    text-transform: capitalize;
	font-family: 'Poppins';
	font-size: 0.9rem;
	color: #fff;
	font-weight: 500;
	text-decoration: underline;
}

#login-screen .resend-otp {
	display: flex;
    align-items: center;
    justify-content: center;
}

#login-screen .resend-otp p {
	font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 500;
    color: #4d4d4db4;
}

#login-screen .resend-otp button {
	color: #1704d1;
	font-family: 'Poppins';
    text-transform: capitalize;
    text-decoration: underline;
}

@media (max-width:1040px) {

	#login-screen .grid-container .image-container {
		width: 35%;
	}

	#login-screen .grid-container .form-container {
		width: 50%;
	}

	#login-screen .login-form {
		padding: 0;
		margin-top: 35px;
	}

}

@media (max-width: 768px) {

	#login-screen {
		margin-top: 2.5rem;
	}

	#login-screen .grid-container .image-container {
		display: none;
	}

	#login-screen .grid-container .form-container {
		width: 100%;
	}

	#login-screen .login-form {
		padding: 0;
		animation: none;
		margin-top: 35px;
	}

	#login-screen .grid-container .form-container {
		animation: none;
	}

	#login-screen .login-form input::placeholder {
		font-size: 15px;
	}
}



/* --------------------------------------- */
/* Reset Password */
/* --------------------------------------- */

#reset-pass,
#reset-pass2  {
	margin-top: 6rem;
	position: relative;
}

#reset-pass .grid-container .left-container,
#reset-pass .grid-container .right-container,
#reset-pass2 .grid-container .left-container,
#reset-pass2 .grid-container .right-container {
  	width: 40%;
	will-change: transform, opacity;
}

#reset-pass .grid-container .left-container,
#reset-pass2 .grid-container .left-container  {
	animation: slideInFromLeft 1s forwards;
}

#reset-pass .grid-container .right-container,
#reset-pass2 .grid-container .right-container  {
	animation: slideInFromRight 1s forwards;
}

#reset-pass .grid-container .left-container img,
#reset-pass2 .grid-container .left-container img  {
	width: 100%;
}

#reset-pass .reset-pass-wrapper,
#reset-pass2 .reset-pass-wrapper  {
	width: 100%;
	padding: 1rem;
    background: linear-gradient(180deg, rgb(235, 235, 249) 10%, rgb(186, 187, 252) 100%);
    border-radius: 12px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#reset-pass .reset-pass-wrapper .reset-pass-header,
#reset-pass2 .reset-pass-wrapper .reset-pass-header {
	padding: 1rem;
	justify-content: center;
}

#reset-pass .reset-pass-wrapper .reset-pass-header h1,
#reset-pass2 .reset-pass-wrapper .reset-pass-header h1 {
	text-align: center;
    font-size: 2.5rem;
    font-family: 'Poppins';
    font-weight: 600;
    color: #4d4d4d;
}

#reset-pass .reset-pass-wrapper .reset-pass-header p,
#reset-pass2 .reset-pass-wrapper .reset-pass-header p {
	margin-bottom: 0.5rem;
	text-align: center;
    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 500;
    color: #4d4d4db4;
}

#reset-pass .reset-form,
#reset-pass2 .reset-form {
	padding: 0 2rem;
}

#reset-pass .reset-form .MuiInputAdornment-root,
#reset-pass2 .reset-form .MuiInputAdornment-root {
	z-index: 999;
}

#reset-pass .reset-form input,
#reset-pass2 .reset-form input {
	padding: 14px;
	border-radius: 27px;
	z-index: 999;
}

#reset-pass .reset-form input:-webkit-autofill,
#reset-pass2 .reset-form input:-webkit-autofill {
	border-radius: 27px;
}

#reset-pass .reset-form fieldset,
#reset-pass2 .reset-form fieldset {
	background-color: #fff;
	border: none;
	border-radius: 27px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

#reset-pass .reset-form input::placeholder,
#reset-pass2 .reset-form input::placeholder{
	font-family: 'Poppins';
    font-weight: 400;
}

#reset-pass .reset-form .reset-button,
#reset-pass2 .reset-form .reset-button {
	background: #fff;
	color: #1704d1;
	text-transform: capitalize;
	font-family: 'Poppins';
	font-size: 1.3rem;
	font-weight: 500;
	padding: 5px 40px;
	border-radius: 31px;
	transition: all 0.3s ease;
}

#reset-pass .reset-form .reset-button:hover,
#reset-pass2 .reset-form .reset-button:hover {
	color: #FBFBFF;
	background: #0d026ca0;
} 

#reset-pass2 .reset-form .reset-button:disabled {
	color: #1704d159;
}

#reset-pass .reset-error-container .MuiGrid-item,
#reset-pass2 .reset-error-container .MuiGrid-item {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 0.5rem;
	padding: 0.5rem;
	border-radius: 5px;
	background-color: #d63031;
	animation: slideInFromRight 1s forwards;
	will-change: transform, opacity;
	
}

#reset-pass .reset-error-container .MuiGrid-item p,
#reset-pass2 .reset-error-container .MuiGrid-item p,
#reset-pass2 .reset-error-container .MuiGrid-item a {
	font-family: 'Poppins';
	font-size: 0.9rem;
	color: #fff;
	font-weight: 500;
}

#reset-pass .reset-error-container .MuiGrid-item svg,
#reset-pass2 .reset-error-container .MuiGrid-item svg {
	fill: #fff;
}

#reset-pass .reset-success-container .MuiGrid-item {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 0.5rem;
	padding: 0.5rem;
	border-radius: 5px;
	background-color: #009432;
	animation: slideInFromRight 1s forwards;
	will-change: transform, opacity;
	
}

#reset-pass .reset-success-container .MuiGrid-item p{
	font-family: 'Poppins';
	font-size: 0.9rem;
	color: #fff;
	font-weight: 500;
}

#reset-pass .reset-success-container .MuiGrid-item svg{
	fill: #fff;
}

#reset-pass2 .reset-form .MuiFormHelperText-root.Mui-error {
	font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 500;
}

.reset-pass-success-box {
	animation: appearFromSmall 1.5s ease-in-out;;
}

.reset-pass-success-box .img-container {
	background-color: #ffffffa1;
    padding: 2rem !important;
    border-radius: 50%;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.reset-pass-success-box .img-container img {
	width: 100px;
}

.reset-pass-success-box h3 {
	text-align: center;
    font-size: 2rem;
    font-family: 'Poppins';
    font-weight: 600;
    color: #4d4d4d;
}

.reset-pass-success-box p {
	text-align: center;
    font-size: 1rem;
    font-family: 'Poppins';
    color: #4d4d4d;
}

.reset-pass-success-box a {
	width: fit-content;
    display: block;
    margin: 0 auto;
	background: #fff;
	color: #1704d1;
	text-transform: capitalize;
	text-decoration: none;
	font-family: 'Poppins';
	font-size: 1.3rem;
	font-weight: 500;
	padding: 5px 40px;
	border-radius: 31px;
	transition: all 0.3s ease;
}

.reset-pass-success-box a:hover {
	color: #FBFBFF;
	background: #0d026ca0;
}

@media (max-width:1040px) {

	#reset-pass .grid-container .left-container, 
	#reset-pass2 .grid-container .left-container {
		width: 35%;
	}

	#reset-pass .grid-container .right-container,
	#reset-pass2 .grid-container .right-container {
		width: 50%;
	}

	#reset-pass .reset-form, #reset-pass2 .reset-form {
		padding: 0;
	}

}


@media (max-width: 768px) {

	#reset-pass .grid-container .left-container, 
	#reset-pass2 .grid-container .left-container {
		display: none;
	}

	#reset-pass .grid-container .right-container,
	#reset-pass2 .grid-container .right-container {
		width: 100%;
	}

	#reset-pass, #reset-pass2 {
		margin-top: 2.5rem;
	}

	#reset-pass .reset-form, #reset-pass2 .reset-form {
		padding: 0;
	}

	#reset-pass .reset-pass-wrapper .reset-pass-header h1, 
	#reset-pass2 .reset-pass-wrapper .reset-pass-header h1 {
		line-height: 3rem;
		margin-bottom: 10px;
	}

}




/* --------------------------------------- */
/* Choose account */
/* --------------------------------------- */

#accounts-screen {
	margin-top: 6rem;
}

#accounts-screen .grid-container .left-container,
#accounts-screen .grid-container .right-container {
	width: 40%;
	will-change: transform, opacity;
}

#accounts-screen .grid-container .left-container {
	animation: slideInFromLeft 1s forwards;
}

#accounts-screen .grid-container .right-container {
	animation: slideInFromRight 1s forwards;
}

#accounts-screen .grid-container .left-container img {
	width: 80%;
	margin: 0 auto;
}

#accounts-screen .choose-account-wrapper {
	width: 100%;
	height: 100%;
	padding: 1rem;
    background: linear-gradient(180deg, rgb(235, 235, 249) 10%, rgb(186, 187, 252) 100%);
    border-radius: 12px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#accounts-screen .choose-account-wrapper h1 {
	text-align: center;
    font-size: 2.5rem;
    font-family: 'Poppins';
    font-weight: 600;
    color: #4d4d4d;
}

#accounts-screen .choose-account-wrapper .btn-wrapper {
	margin-top: 3rem;
}

#accounts-screen .choose-account-wrapper .account-btn {
	padding: 1rem 1rem;
	border-radius: 31px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
	background-color: #fff;
	transition: all 1s ease;
}

.account-btn:hover{
	cursor: pointer;
	transform: scale(1.03);
}

#accounts-screen .choose-account-wrapper .account-btn h6 {
	color: #1704d1;
	text-transform: capitalize;
	text-decoration: none;
	font-family: 'Poppins';
	font-size: 1.3rem;
	font-weight: 500;
}




