#drawer-container {
  	position: relative !important;
}

#drawer-container .MuiDrawer-paper {
  	position: absolute !important;
	height: calc(100vh - 200px);
  	top: 20px !important;
  	background: #fff;
  	box-shadow: 0rem 1.25rem 1.6875rem 0rem rgb(0 0 0 / 5%);
  	border-radius: 12px;
  	color: #fff;
}

.MuiDrawer-paper span {
  	color: #4c4c4c !important;
	font-family: "Poppins";
	font-weight: 500;
	font-size: 1rem;
	overflow: hidden;
	text-overflow: ellipsis;
}

.MuiDrawer-paper svg {
  	fill: #afafaf;
}

.list-closed li{
	width: 50px;
    height: 50px;
    margin: 0.5rem auto;
	padding: 0;
}

.drawer-close-button {
  display: flex;
  justify-content: flex-end;
}

.list-group-item.active span {
	color: #000 !important;
}

.list-group-item {
	transition: all 0.3s ease;
	border-radius: 12px;
}

.list-group-item:hover {
	box-shadow: -3px -3px 5px rgba(255, 255, 255, 1), 3px 3px 5px rgba(29, 53, 208, 0.8);
	background-color: #fff !important;
	border-radius: 12px;
}

.list-group-item.active {
	box-shadow: inset -10px -10px 8px rgba(255, 253, 253, 0.7), inset 2px 2px 5px rgba(29, 53, 208, 0.8);
	border-radius: 12px;
}

.list-group-item.active svg{
	fill: rgba(29, 53, 208, 0.8);
}

#drawer-container .client-profile-drawer-header {
	justify-content: space-between;
}

#drawer-container .client-profile-drawer-header .single-user-page {
	flex: 1;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
}

#drawer-container .client-profile-drawer-header .merged-user-page {
	font-family: Poppins;
    font-size: 1rem;
    font-weight: 500;
    color: #333;
}

/* .side-nav.closed {
  display: none;
} */


/* --------------------------------------- */
/* General admin styles */
/* --------------------------------------- */

.admin-dashboard h1, 
#settings-page h1 {
    position: relative;
    width: fit-content;
    /* margin-left: 1rem; */
    font-size: 2.3rem;
    font-family: "Playfair Display", serif;
    color: rgba(0, 0, 0, 0.8);
    font-weight: 500;
    display: inline-block;
    padding: 0 10px;
}

.admin-dashboard h1::after,
#settings-page h1::after {
	content: ' ';
    position: absolute;
    display: block;
    bottom: 0;
    right: -5px;
    height: 4px;
    width: 40px;
    background-color: rgb(29, 53, 208);
}

.admin-dashboard a {
	text-decoration: none;
}

#success-msg-dialog {
	padding-bottom: 2rem;
}

#success-msg-dialog img {
  	width: 100px;
}

.dialog-error-msg {
	margin-bottom: 2rem !important;
    font-weight: 500 !important;
	color: #BF0000 !important;
	text-transform: capitalize;
	font-family: "Poppins" !important;
	letter-spacing: 1px !important;
	font-size: 1.3rem !important;
}


/* .admin-dashboard .admin-open-button {
	background-color: rgba(252, 164, 0, 1) !important;
  	color: #fff !important;
  	padding: 0.5rem 2rem !important;
  	font-size: 1rem !important;
  	font-weight: 600 !important;
} */

/* .admin-submit-button {
	background: linear-gradient(195deg, #c244f3, #4b92ef);
	color: #ffffff !important;
	box-shadow: 0rem 0.1875rem 0.1875rem 0rem rgb(26 115 232 / 15%), 0rem 0.1875rem 0.0625rem -0.125rem rgb(26 115 232 / 20%), 0rem 0.0625rem 0.3125rem 0rem rgb(26 115 232 / 15%);
  	padding: 0.5rem 2rem !important;
  	font-size: 1rem !important;
  	font-weight: 600 !important;

} */


/* --------------------------------------- */
/* Admin Questionnaire View */
/* --------------------------------------- */

.questionnaire-list-container .add-que-btn {
	background-color: rgb(29, 53, 208);
	color: white;
	border: none;
	padding: 7px 35px;
	border-radius: 25px;
	text-transform: capitalize;
    font-size: 16px;
	font-weight: 500;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease;
	text-transform: none;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.questionnaire-list-container .add-que-btn:hover {
	background-color: rgb(16 33 148);
}


/* Group Container */


.questionnaire-group {
	margin-bottom: 2rem;
}

.questionnaire-group .questionnaire-group-title {
	font-size: 21px; 
  	font-weight: 600; 
  	color: #1A3C5E;
  	margin-bottom: 15px;
	border-bottom: 2px solid #ddd;
	padding-bottom: 0.5rem;
	font-family: "Poppins";
}


.questionnaire-group .questionnaire-item {
	/* border: 1px solid #e0e0e0; */
	/* background-color: #fff; */
	border-radius: 12px;
	padding: 1rem;
	margin-bottom: 1.5rem;
	/* border-bottom: 1px solid #D3D3D3; */
	/* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.04); */
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

/* Question Number */
.questionnaire-group .questionnaire-avatar {
	background-color: #E6F0FA;
	color: #555555;
	font-weight: 500;
	font-size: 17px;
}


.questionnaire-group .questionnaire-question-text .MuiTypography-root {
	font-weight: 500;
	font-size: 17px;
	color: #333;
	font-family: "Poppins";
}

.questionnaire-group .question-type {
	font-style: italic;
	color: #555;
	font-size: 12px;
	background-color: #E8ECEF;
	padding: 3px 10px;
  	border-radius: 12px;
  	margin-left: 8px;
	white-space: nowrap;
	font-weight: 500;
}

.questionnaire-group .MuiIconButton-root svg {
	fill: #777;
	font-size: 22px;
}

.questionnaire-group .MuiIconButton-root:hover svg {
	fill: #007BFF;
}

/* Sub Questions */
.questionnaire-group .subquestion-container {
	margin-top: 1rem;
	background-color: #f1f1f1;
	padding: 0.75rem 1rem;
	border-left: 4px solid #0b1fd1;
	border-radius: 6px;
}

.questionnaire-group .subquestion-title {
	color: #0b1fd1;
	font-weight: 600;
	font-style: italic;
	margin-bottom: 0.5rem;
	font-size: 1rem;
	font-family: "Poppins";
}

.questionnaire-group .subquestion-item {
	font-size: 0.95rem;
	margin-left: 0.5rem;
	color: #333;
	font-family: "Poppins";
}

/* Notes */
.questionnaire-group .note-container {
	margin-top: 1rem;
	background-color: #fff8e1;
	padding: 0.75rem 1rem;
	border-left: 4px solid #fbc02d;
	border-radius: 6px;
}

.questionnaire-group .note-title {
	font-weight: 600;
	font-size: 1rem;
	color: #f9a825;
	font-style: italic;
	margin-bottom: 0.3rem;
	font-family: "Poppins";
}

.questionnaire-group .note-text {
	font-size: 0.95rem;
	color: #333;
	margin-left: 0.5rem;
	font-family: "Poppins";
}

/* Additional style for Document Notes */
.questionnaire-group .note-docs {
	background-color: #e3f2fd;
	border-left-color: #2196f3;
}

.questionnaire-group .note-docs .note-title {
	color: #1976d2;
	font-family: "Poppins";
}
  

/* --------------------------------------- */
/* Update Question View */
/* --------------------------------------- */

.update-question-container .MuiGrid-item,
#add-que-modal .MuiGrid-item{
	padding-left: 0 !important;
}

.update-question-container .sub-que-wrapper .MuiGrid-item,
#add-que-modal .sub-que-wrapper .MuiGrid-item {
	padding: 10px !important;
}

.update-question-container .sub-que-wrapper, 
#add-que-modal .sub-que-wrapper{
	padding-top: 0 !important;
}

.update-question-container .sub-que-wrapper .sub-que-actions,
#add-que-modal .sub-que-wrapper .sub-que-actions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0.5rem;
	/* padding-top: 0 !important; */
}

.update-question-container .sub-que-wrapper, 
#add-que-modal .sub-que-wrapper {
	background-color: #1976d211;
	border-radius: 12px;
}

.update-question-container .add-sub-que-btn, 
#add-que-modal .add-sub-que-btn{
	background-color: #000000;
	color: #FFFFFF;
	border-radius: 12px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

.update-question-container .MuiFormLabel-root, 
#add-que-modal .MuiFormLabel-root{
	font-size: 1rem;
	color: rgb(55, 55, 55);
	font-family: "Poppins";
	font-weight: 400;
}

.update-question-container .MuiFormGroup-root .MuiTypography-root,
#add-que-modal .MuiFormGroup-root .MuiTypography-root{
	font-size: 1rem;
	color: rgb(55, 55, 55);
	font-family: "Poppins";
	font-weight: 400;
}

.update-question-container .MuiInputBase-root, 
#add-que-modal .MuiInputBase-root{
	font-size: 18px;
	color: rgba(55, 55, 55, 0.9);
	font-family: "Poppins";
	font-weight: 400;
}


.update-question-container .update-que-header .MuiIconButton-root:hover,
#add-que-modal .add-que-header .MuiIconButton-root:hover{
	color: #FFA500;
}

.update-question-container .update-que-btn, #add-que-modal .add-que-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;

}

.update-question-container .update-que-btn:hover,
#add-que-modal .add-que-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

/* --------------------------------------- */
/* Admin Delete Question View */
/* --------------------------------------- */

#delete-question-modal .MuiIconButton-root:hover {
	color: #FFA500;;
}

#delete-question-modal h6 {
	color: #222;
	font-weight: 500;
	font-family: "Poppins";
}

#delete-question-modal p {
	font-size: 1rem;
	color: #5b5b5b;
	font-weight: 500;
	font-family: "Poppins";
}

#delete-question-modal .delete-button {
	max-width: 185px;
	width: 185px;
	background-color: #BF0000;
	color: white;
	border: 1px solid #BF0000;
	padding: 8px 5px;
	border-radius: 17px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#delete-question-modal .delete-button:hover {
	background-color: #800000; 
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

#delete-question-modal .cancel-button {
	max-width: 185px;
	width: 185px;
	background-color: transparent;
	border: 1px solid #4b4b4b74;
	color: #4B4B4B; 
	padding: 8px 5px;
	border-radius: 17px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;
}

#delete-question-modal .cancel-button:hover {
	background-color: #4B4B4B;
	color: white;
	border: 1px solid #4B4B4B; 
}

#delete-question-modal .delete-button.Mui-disabled{
	opacity: 0.5 !important;
	cursor: not-allowed;
	border: 1px solid #4b4b4b74;
	box-shadow: none;
}

/* --------------------------------------- */
/* Admin Engagement Letter View */
/* --------------------------------------- */
.edit-eng-letter-btn-wrapper {
	column-gap: 1rem;
}

.edit-eng-letter-btn-wrapper .edit-cancel-btn {
	background-color: transparent;
	border: 1px solid #4b4b4b74;
	color: #4B4B4B; 
	padding: 8px 48px;
	border-radius: 25px;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	transition: all 0.3s ease;
}

.edit-eng-letter-btn-wrapper .edit-update-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

.edit-eng-letter-btn-wrapper .edit-cancel-btn:hover {
	background-color: #4B4B4B;
	color: white;
	border: 1px solid #4B4B4B; 
	box-shadow: 0px 2px 2px -1px #4B4B4B;
}

.edit-eng-letter-btn-wrapper .edit-update-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

/* --------------------------------------- */
/* Admin Folders View */
/* --------------------------------------- */

#admin-folders .add-folders-btn {
	background-color: rgb(29, 53, 208);
	color: white;
	border: none;
	padding: 7px 35px;
	border-radius: 25px;
	text-transform: capitalize;
    font-size: 16px;
	font-weight: 500;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease;
	text-transform: none;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

#admin-folders .add-folders-btn:hover {
	background-color: rgb(16 33 148);
}

#admin-folders .folder-item {
	display: flex;
	gap: 1rem;
	align-items: center;
}


.folder-item .folder-box {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px; /* Adjusted height */
    border: 1px solid #e0e0e0;
	width: 100%;
}

.folder-item .folder-box:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.folder-item .folder-box .folder-icon {
    width: 80px;
   height: 90px;
}

.folder-item .folder-box .MuiIconButton-root {
    color: #757575;
}

.folder-item .folder-box .MuiIconButton-root:hover {
    color: #000;
}

.folder-item .folder-box .folder-name {
    margin-top: 8px;
    margin-bottom: 4px;
    font-size: 1.2rem;
	color: #000;
	text-transform: capitalize;
	font-family: "Poppins";
	font-weight: 500;
}

#admin-folders .folder-item p {
	font-size: 0.9rem;
	font-family: "Poppins";
	font-weight: 400;
}


/* --------------------------------------- */
/* Admin Eng Letter View */
/* --------------------------------------- */

#admin-eng-letter .eng-letter-root {
	padding: 32px;
	background-color: #f9fafa;
}
  
  
#admin-eng-letter .eng-letter-btn {
	background-color: rgb(29, 53, 208);
	color: #fff;
	font-weight: 500;
	border-radius: 25px;
	padding: 7px 35px;
	transition: 0.3s ease;
	font-family: 'Poppins';
    text-transform: capitalize;
    font-size: 16px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

#admin-eng-letter .eng-letter-btn:hover {
	background-color: rgb(16 33 148);
}
  
#admin-eng-letter .eng-letter-card {
	padding: 32px;
	border-radius: 12px;
	background: #ffffff;
	box-shadow: 0 8px 24px rgba(0,0,0,0.06);
}
  
#admin-eng-letter .eng-letter-header {
	margin-bottom: 24px;
}
  
#admin-eng-letter .eng-letter-date {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

#admin-eng-letter .eng-letter-date p {
	font-family: 'Poppins';
}
  
#admin-eng-letter .eng-date-label {
	font-size: 0.9rem;
	font-weight: 500;
	color: #7a7a7a;
}
  
#admin-eng-letter .eng-date-text {
	font-size: 1rem;
	font-weight: 500;
	color: #1e1e1e;
}
  
#admin-eng-letter .eng-letter-body {
	line-height: 1.7;
	color: #333;
}
  
#admin-eng-letter .eng-paragraph {
	margin-bottom: 16px;
	font-size: 1rem;
	font-family: 'Poppins';
	color: #7a7a7a;
}


#add-eng-letter-modal .admin-submit-button {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;

}

#add-eng-letter-modal .admin-submit-button:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

/* --------------------------------------- */
/* Admin Free Quote View */
/* --------------------------------------- */

#freeQuote-wrapper .free-quote-list {
	margin-top: 2rem;
}

#freeQuote-wrapper .free-quote-list .quote-list-item{
	border-bottom: 1px solid #ccc;
	box-shadow: rgba(0, 0, 0, 0.45) 0px 15px 10px -20px;
	margin-bottom: 0.5rem;
}

#freeQuote-wrapper .free-quote-list .quote-list-item p {
	width: 40%;
	font-family: "Poppins";
	color: #202020;
	font-weight: 500;
}

#freeQuote-wrapper .free-quote-list .quote-list-item span {
	font-family: "Poppins";
	color: #202020;
	font-size: 1.1rem;
}

#freeQuote-wrapper .free-quote-list .quote-list-item .pending  {
	width: 121px;
	margin-right: 1rem;
	background-color: #BF0000;
	border: none;
	font-size: 0.9rem;
    font-weight: 500;
	font-family: "Poppins";
}

#freeQuote-wrapper .free-quote-list .quote-list-item .completed  {
	width: 121px;
	margin-right: 1rem;
	background-color: rgb(4, 117, 19);
	border: none;
	font-size: 0.9rem;
    font-weight: 500;
	font-family: "Poppins";
}

#freeQuote-wrapper .free-quote-list .quote-list-item .completed span,
#freeQuote-wrapper .free-quote-list .quote-list-item .pending span  {
	color: white;
}

/* --------------------------------------- */
/* Admin Free Quote Modal */
/* --------------------------------------- */

#admin-free-quote-modal .info-item {
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
	margin-bottom: 1rem;
}

#admin-free-quote-modal .info-item-lg {
	margin-bottom: 1rem;
}

#admin-free-quote-modal .info-item h6, 
#admin-free-quote-modal .info-item-lg h6  {
	font-size: 1.3rem;
	font-family: "Poppins", serif;
	color: rgba(0, 0, 0, 0.9);
	font-weight: 500;
}

#admin-free-quote-modal .info-item p, 
#admin-free-quote-modal .info-item-lg p {
	font-weight: 400;
	font-size: 1.1rem;
	color: rgba(0, 0, 0, 0.7);
	font-family: "Poppins", serif;
}

#admin-free-quote-modal .complete-btn {
	background-color: rgb(4, 117, 19);
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#admin-free-quote-modal .complete-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: rgb(2, 90, 13); 
}

#admin-free-quote-modal .pending-btn {
	background-color: #BF0000;
	color: white;
	border: 1px solid #BF0000;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#admin-free-quote-modal .pending-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #800000;
}

/* --------------------------------------- */
/* Admin Delete Free Quote View */
/* --------------------------------------- */

#delete-free-quote-modal .MuiIconButton-root:hover {
	color: #FFA500;;
}

#delete-free-quote-modal h6 {
  color: #222;
  font-weight: 500;
  font-family: "Poppins";
}

#delete-free-quote-modal p {
  font-size: 1rem;
  color: #5b5b5b;
  font-weight: 500;
  font-family: "Poppins";
}

#delete-free-quote-modal .delete-button {
  max-width: 185px;
  width: 185px;
  background-color: #BF0000;
  color: white;
  border: 1px solid #BF0000;
  padding: 8px 5px;
  border-radius: 17px;
  font-size: 1.2rem;
  font-family: "Poppins";
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-transform: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#delete-free-quote-modal .delete-button:hover {
  background-color: #800000; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

#delete-free-quote-modal .cancel-button {
  max-width: 185px;
  width: 185px;
  background-color: transparent;
  border: 1px solid #4b4b4b74;
  color: #4B4B4B; 
  padding: 8px 5px;
  border-radius: 17px;
  font-size: 1.2rem;
  font-family: "Poppins";
  cursor: pointer;
  text-transform: none;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease-in-out;
}

#delete-free-quote-modal .cancel-button:hover {
  background-color: #4B4B4B;
  color: white;
  border: 1px solid #4B4B4B; 
}

#delete-free-quote-modal .delete-button.Mui-disabled{
  opacity: 0.5 !important;
  cursor: not-allowed;
  border: 1px solid #4b4b4b74;
  box-shadow: none;
}


/* --------------------------------------- */
/* Admin Personal Info View */
/* --------------------------------------- */

#personal-info .inactive-account-msg {
	display: flex;
	column-gap: 0.3rem;
}

#personal-info .inactive-account-msg span{
	font-size: 0.9rem;
	color: #BF0000;
}

#personal-info .inactive-account-msg svg {
	width: 1.2rem;
	height: 1.2rem;
	fill: #BF0000;
}

#personal-info .inactive-account-msg svg:hover {
	cursor: pointer;
}

#personal-info .section {
	margin-bottom: 16px;
	cursor: pointer;
	transition: all 0.3s ease;
	/* border-bottom: 4px solid rgba(50, 50, 93, 0.25); */
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	border-radius: 12px;
	transition: all 0.3s ease;
}

/* #personal-info .section.open,  
#personal-info .section:hover {
	border-bottom-color: rgba(29, 53, 208, 0.5);
	box-shadow: rgba(29, 53, 208, 0.19) 0px 10px 20px, rgba(29, 53, 208, 0.23) 0px 6px 6px;
} */
  
#personal-info  .section-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
}

#personal-info  .section-header h3 {
	font-size: 1.6rem;
	font-family: "Playfair Display", serif;
	color: rgba(0, 0, 0, 0.9);
	font-weight: 600;
}
  
#personal-info .section-content {
	padding: 16px;
}
  
#personal-info .field {
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
}
  
#personal-info  label {
	width: 200px;
	font-weight: 500;
	font-size: 1.2rem;
	color: rgba(0, 0, 0, 0.9);
	font-family: "Poppins", serif;
}

#personal-info .individual-client label {
	width: 270px;
} 
  
#personal-info .field span {
	flex-grow: 1;
	font-family: "Poppins", serif;
  	color: rgba(0, 0, 0, 0.9);
  	font-weight: 300;
	font-size: 1.1rem;
}

#personal-info .field span a {
	color: rgba(0, 0, 0, 0.9);
	transition: all 0.3s ease-in-out;
}

#personal-info .field span a:hover {
	color: rgba(0, 0, 0, 0.7);
}

#personal-info .open-return-btn {
	background-color: rgba(255, 150, 22, 1);
	color: white;
	border: none;
	padding: 10px 28px;
	border-radius: 12px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

}

#personal-info .open-return-btn:hover {
	background-color: rgba(255, 120, 0, 1);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

#personal-info .return-type-item {
	min-width: 100px;
	padding: 0.5rem;
	border-radius: 5px;
	background-color: #1565C0;
	text-align: center;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#personal-info .return-type-item p{
	font-size: 1rem;
	font-family: "Poppins";
	font-weight: 500;
	color: #FFF;
}

#admin-return-dialog .submit-return-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;

}

#admin-return-dialog .submit-return-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}

#admin-return-dialog .success-msg {
	padding-bottom: 2rem;
}

#admin-return-dialog .success-msg img {
  	width: 100px;
}

#admin-return-dialog .MuiIconButton-root:hover {
	color: #FFA500;
}

#admin-return-dialog .dialog-error-msg {
	margin-bottom: 2rem;
    font-weight: 500;
	color: #BF0000;
	text-transform: capitalize;
	font-family: "Poppins";
	letter-spacing: 1px;
	font-size: 1.3rem;
}

#personal-info .business-info .business-item {
	padding: 1rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

#personal-info .business-info .active-business-status span, 
#personal-info .business-info .active-business-status-error span{
	color: #fff;
	font-size: 0.8rem;
    font-weight: 500;
	
}

#personal-info .business-info .active-business-status {
	background-color: blue;
}

#personal-info .business-card {
	border-radius: 16px;
	min-height: 140px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	transition: 0.2s ease;
}

#personal-info .business-card:hover {
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
	transform: translateY(-2px);
}

#personal-info .business-card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 16px 0 16px;
}

#personal-info .business-card-header.only-icon {
	justify-content: flex-end;
}

#personal-info .business-chip-stack {
	display: flex;
	gap: 8px;
}

#personal-info .chip-frozen {
	background-color: #d0e9ff;
	color: white !important;
}

#personal-info .chip-frozen span {
	color: #0d47a1 !important;
	/* font-family: 'Poppins'; */
	font-size: 0.8125rem;
	font-weight: 500;
}

#personal-info .chip-dissolved {
	background-color: #fdecea;
    color: #d93025;
}

#personal-info .business-link {
	text-decoration: none;
}

#personal-info .business-name-box {
	margin-top: 8px;
}

#personal-info .business-name-label {
	font-size: 14px;
	color: #555;
	font-weight: 500;
	font-family: 'Poppins';
	padding: 5px 16px;
	margin-bottom: 10px;
	background-color: #f6f6f6;
}

#personal-info .business-name-value {
	font-size: 18px;
	font-weight: 600;
	color: #1a1a1a;
	font-family: 'Poppins';
	padding: 0 16px 16px 16px;
}

#personal-info .business-info .field {
	margin-bottom: 0.5rem;
}

#personal-info .business-info label {
	font-size: 1.1rem;
}

#admin-business-dialog .MuiFormHelperText-root {
	font-size: 0.7rem;
	margin-left: 0;
}

#admin-business-dialog .submit-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#admin-business-dialog .submit-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}


/* --------------------------------------- */
/* Admin Files List View */
/* --------------------------------------- */

#admin-folders-files-container .MuiBreadcrumbs-li p {
	text-decoration: none;
	color: #444;
	font-family: 'Poppins';
	font-weight: 500;
}

#admin-folders-files-container .MuiBreadcrumbs-li .breadcrumb-main {
	color: #777;
	font-weight: 400;
}

#admin-folders-files-container .MuiBreadcrumbs-li .breadcrumb-main:hover {
	cursor: pointer;
	text-decoration: underline;
}

#admin-folders-files-container .MuiMenuItem-root {
  	font-weight: bold;
}

#admin-folders-files-container .MuiSelect-root {
  	padding: 10px;
}

#admin-folders-files-container .files-go-back-btn {
	border: 1px solid #682286;
    background-color: transparent;
    font-size: 1rem;
    text-transform: capitalize;
    font-family: 'Poppins';
    color: #682286;
    padding: 0.2rem 1rem;
    border-radius: 21px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	transition: all 0.5s ease;
}

#admin-folders-files-container .files-go-back-btn:hover {
	background-color: #682286;
	color: #fff;
}

#admin-folders-files-container .top-section {
	display: flex;
	justify-content: space-between;
	width: 90%;
}

#admin-folders-files-container .breadcrumb-container {
  	flex-grow: 1;
}

#admin-folders-files-container .year-select-container {
  	min-width: 150px;
	height: 45px;
}

#admin-folders-files-container .year-select-container .MuiInputBase-root {
	height: 45px;
}

#admin-folders-files-container .MuiListItemSecondaryAction-root {
	display: flex;
	align-items: center;
}

#admin-folders-files-container .file-list {
	width: 90%;
	min-height: 488px;
	margin-top: 1rem;
}
#admin-folders-files-container .file-list-item .file-icon {
	width: 50px;
	padding-right: 1rem;
}

#admin-folders-files-container .file-list-item {
	border-bottom: 1px solid #ccc;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
	border-radius: 5px;
	margin-bottom: 0.5rem;
	transition: transform 0.3s ease-in-out;
	font-family: "Poppins";
}

#admin-folders-files-container .file-list-item a {
	width: 100%;
}

/* #admin-folders-files-container .file-list-item:hover {
  	transform: scale(1.005);
} */

#admin-folders-files-container .file-list-item a {
	display: flex;
	align-items: center;
	text-decoration: none;
	color: #333;
}

#admin-folders-files-container .MuiListItemText-primary {
  	color: black;
}

#admin-folders-files-container .file-list-item .MuiIconButton-root {
  	padding: 8px;
}

#admin-folders-files-container .file-list-item .MuiListItemSecondaryAction-root > * + * {
  	margin-left: 8px;
}

#admin-folders-files-container .multiple-edit-btn-wrapper {
	column-gap: 1rem;
}

#admin-folders-files-container .multiple-edit-btn-wrapper .select-all-btn {
	width: 85px;
	background-color: transparent;
	border: 1px solid #4b4b4b74;
	color: #4B4B4B; 
	padding: 5px;
	border-radius: 5px;
	font-size: 0.9rem;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;

}

#admin-folders-files-container .multiple-edit-btn-wrapper .select-all-btn:hover {
	background-color: #4B4B4B;
	color: white;
	border: 1px solid #4B4B4B; 
}

#admin-folders-files-container .multiple-edit-btn-wrapper .multiple-edit-btn {
	width: 85px;
	background-color: rgba(255, 150, 22, 1);
	border: 1px solid rgba(255, 150, 22, 1);
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.9rem;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;

}

#admin-folders-files-container .multiple-edit-btn-wrapper .multiple-edit-btn:hover {
	background-color: rgba(255, 120, 0, 1);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

#admin-folders-files-container .multiple-edit-btn-wrapper .multiple-copy-btn {
	width: 85px;
	background-color: rgb(23, 78, 198);
	border: 1px solid rgb(23, 78, 198);
	color: white;
	padding: 5px;
	border-radius: 5px;
	font-size: 0.9rem;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;
}

#admin-folders-files-container .multiple-edit-btn-wrapper .multiple-copy-btn:hover {
	background-color: rgb(4, 49, 146);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}


/* --------------------------------------- */
/* Admin Edit Files View */
/* --------------------------------------- */

#admin-doc-edit-modal .edit-dialog-header {
  	margin-bottom: 16px;
}

#admin-doc-edit-modal .edit-dialog-header .MuiChip-root {
	margin-right: 16px;
	background-color: #FFA500;
	color: #FFFFFF;
}

#admin-doc-edit-modal .edit-dialog-header .MuiIconButton-root {
  	color: #000000;
}

#admin-doc-edit-modal .edit-dialog-header .MuiIconButton-root:hover {
  	color: #FFA500;
}

#admin-doc-edit-modal .edit-dialog-header .MuiIconButton-root:focus {
  	color: #FFA500;
}

#admin-doc-edit-modal .edit-dialog .MuiFormControl-root {
  	margin-bottom: 16px;
}

.MuiListSubheader-root.folder-select-subheader {
	font-family: "Poppins";
	font-size: 1.2rem;
	font-weight: 500;
	border-bottom: 1px solid rgba(0, 0, 0, 0.09);
	margin-bottom: 10px;
	/* background-color: #0a54d41f;
	margin-bottom: 10px; */
}

.MuiMenuItem-root.folder-select-item {
	font-family: "Poppins";
	font-size: 1rem;
	font-weight: 400;
}

.MuiMenuItem-root.folder-select-item.Mui-selected {
    background-color: rgba(0, 0, 0, 0.03);
}

#admin-doc-edit-modal .submit-button {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#admin-doc-edit-modal .submit-button:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0; 
}


/* --------------------------------------- */
/* Admin Add Files View */
/* --------------------------------------- */

#admin-documents-dialog .upload-dialog-header .MuiChip-root {
	margin-right: 16px;
	background-color: #FFA500;
	color: #FFFFFF;
}

#admin-documents-dialog .upload-dialog-header .MuiIconButton-root {
  	color: #000000;
}

#admin-documents-dialog .upload-dialog-header .MuiIconButton-root:hover {
  	color: #FFA500;;
}

#admin-documents-dialog .upload-dialog-header .MuiIconButton-root:focus {
  	color: #FFA500;;
}

#admin-documents-dialog .hidden-file {
	display: none;
}

#admin-documents-dialog .dropZone {
  	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	padding: 2rem 0;
	border: 1px dashed rgb(45,168,253);
	border-radius: 12px;
	background: rgb(34,193,195);
	background: linear-gradient(0deg, rgba(34,193,195,0) 0%, rgba(45,168,253,0.3) 100%);
}

#admin-documents-dialog .text-wrapper {
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
}
  
#admin-documents-dialog .text-wrapper img{
	width: 50px;
}

#admin-documents-dialog .text-wrapper button {
	width: 60%;
	background-color: rgb(0, 140, 255) !important;
	color: #fff;
	padding: 0.5rem!important;
	font-size: 0.9rem !important;
	text-transform: capitalize;
}
  
#admin-documents-dialog .preview-files .MuiIconButton-root svg {
	width: 0.9rem;
	height: 0.9rem;
}

#admin-documents-dialog .preview-files .MuiIconButton-root:hover {
  	color: #000;
}

#admin-documents-dialog ul {
	padding-top: 0;
	max-height: 210px;
	overflow-y: scroll;
}

#admin-documents-dialog ul::-webkit-scrollbar {
  	width: 3px; 
}

#admin-documents-dialog ul::-webkit-scrollbar-track {
  	background-color: #F5F5F5; 
}

#admin-documents-dialog ul::-webkit-scrollbar-thumb {
	background-color: #888; 
	border-radius: 5px; 
}

#admin-documents-dialog ul::-webkit-scrollbar-thumb:hover {
	background-color: #555;
	cursor: pointer;
}

#admin-documents-dialog ul::-webkit-scrollbar-thumb:focus {
  	cursor: pointer;
}

#admin-documents-dialog ul li {
  	width: 98%;
}

.admin-uploaded-file {
	margin-bottom: 0.5rem;
	border: #989898 1px solid;
	border-radius: 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.admin-uploaded-file span{
	font-size: 0.8rem;
	font-weight: 500;
	font-family: "Poppins";
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#admin-documents-dialog .file-icon {
  	width: 30px;
}

#admin-documents-dialog .add-files-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#admin-documents-dialog .add-files-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0;
}

/* --------------------------------------- */
/* Admin Delete Files View */
/* --------------------------------------- */

#admin-documents-dialog .MuiIconButton-root:hover {
  	color: #FFA500;;
}

#admin-documents-dialog h6 {
	color: #222;
	font-weight: 500;
	font-family: "Poppins";
}

#admin-documents-dialog p {
	font-size: 1rem;
	color: #5b5b5b;
	font-weight: 500;
	font-family: "Poppins";
}

#admin-documents-dialog .delete-button {
	max-width: 185px;
	width: 185px;
	background-color: #BF0000;
	color: white;
	border: 1px solid #BF0000;
	padding: 8px 5px;
	border-radius: 17px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#admin-documents-dialog .delete-button:hover {
	background-color: #800000; 
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

#admin-documents-dialog .cancel-button {
	max-width: 185px;
	width: 185px;
	background-color: transparent;
	border: 1px solid #4b4b4b74;
	color: #4B4B4B; 
	padding: 8px 5px;
	border-radius: 17px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;
}

#admin-documents-dialog .cancel-button:hover {
	background-color: #4B4B4B;
	color: white;
	border: 1px solid #4B4B4B; 
}

#admin-documents-dialog .delete-button.Mui-disabled{
	opacity: 0.5 !important;
	cursor: not-allowed;
	border: 1px solid #4b4b4b74;
	box-shadow: none;
}


/* --------------------------------------- */
/* Admin folders view  */
/* --------------------------------------- */

#folders-header {
	margin-bottom: 30px;
	padding: 0 1rem;
}

.folders-btn-container {
	padding-left: 26px;
}

.folders-btn-container button {
	/* background-color: rgba(255, 150, 22, 1);
	color: white;
	border: none;
	padding: 10px 48px;
	border-radius: 12px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 30px;
	font-family: "Poppins";
	cursor: pointer;
	font-weight: 600;
	transition: all 0.3s ease-in-out;
	text-transform: capitalize;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.folders-btn-container button:hover {
	background-color: rgba(255, 120, 0, 1);
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.inactive-account-msg {
	display: flex;
	column-gap: 0.3rem;
	padding-right: 20px;
}

.inactive-account-msg span{
	font-size: 0.9rem;
	color: #BF0000;
}

.inactive-account-msg svg {
	width: 1.2rem;
	height: 1.2rem;
	fill: #BF0000;
}

.inactive-account-msg svg:hover {
	cursor: pointer;
}

#admin-folders-container {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

#admin-folders-container img {
	display: block;
	width: 80px;
	height: 80px;
	margin-bottom: 10px;
}

#admin-folders-container .MuiTypography-root {
	font-size: 14px;
	font-weight: 500;
	font-family: "Poppins";
	color: #222222;
}

#admin-folders-container .folder-access {
	font-size: 12px;
	font-weight: 400;
	font-family: "Poppins";
	color: #454545;
	font-style: italic;
	letter-spacing: 0.5px;
}

#admin-folders-container .MuiGrid-item {
  	text-align: center;
}

#admin-folders-container .MuiTypography-caption {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 16px;
	font-weight: 500;
	background: linear-gradient(to bottom right, rgb(97, 97, 97), rgb(26, 25, 25)) !important;
	padding: 2px 5px;
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0.8;
}

#admin-folders-container .folder-box {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 200px;
	max-width: 230px;
	background-color: #c4c3c328;
	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	transition: box-shadow 0.3s ease-in-out;
	cursor: pointer;
}

#admin-folders-container .folder-box:hover {
  	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
}

#admin-folders-container .folder-box img {
	width: 100px;
	height: 100px;
	object-fit: contain;
	margin-bottom: 10px;
}

#admin-folders-container .folder-content {
	display: flex;
    flex-direction: column;
    align-items: center;
	padding-top: 8px;
}

#admin-folders-container .folder-settings-btn {
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 2;
}

.folder-menu .MuiPaper-root {
	min-width: 160px;
	padding: 4px 0;
	border-radius: 10px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
  
.folder-menu .MuiMenuItem-root {
	display: flex;
	align-items: center;
	padding: 8px 12px;
	transition: background-color 0.2s ease;
}
  
.folder-menu .MuiMenuItem-root:hover {
	background-color: #f5f5f5;
}
  
.menu-icon {
	margin-right: 10px;
	color: #555;
}
  
.menu-text {
	font-size: 0.95rem;
	color: #333;
	font-weight: 400;
	font-family: "Poppins";
}

  

/* --------------------------------------- */
/* Admin Add Folders Dialog */
/* --------------------------------------- */ 

#admin-folders-dialog .MuiPaper-root, #update-folder-modal .MuiPaper-root {
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

#admin-folders-dialog .add-folder-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #278629;
}

#admin-folders-dialog .add-folder-btn svg {
	height: 30px;
	width: 30px;
}

#admin-folders-dialog .submit-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#admin-folders-dialog .submit-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0;
}

#update-folder-modal .update-folder-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#update-folder-modal .update-folder-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0;
}
/* --------------------------------------- */
/* Client List view */
/* --------------------------------------- */

#clientsList-wrapper .clients-container {
  	margin-top: 3rem;
}

#clientsList-wrapper .box {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* width: 350px; */
	height: 250px;
	border-radius: 10px;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

#clientsList-wrapper .box:hover {
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
	transform: scale(1.01);
}

#clientsList-wrapper .box .paper-header {
	background-color: #e5e5e559;
	width: 95%;
	margin: 0 auto !important;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

#clientsList-wrapper .box .paper-header .MuiGrid-item {
  	padding: 5px!important;
}

#clientsList-wrapper .box .paper-header .active-avatar {
	background-color: green;
}

#clientsList-wrapper .box .paper-header .archived-avatar {
	background-color: red
}

#clientsList-wrapper .box .paper-header .client-list-tag-container {
	flex: 1;
	display: flex;
	justify-content: flex-end;
}

#clientsList-wrapper .box .paper-header .client-list-tag-container .MuiChip-root {
	height: 25px;
    font-family: 'Poppins';
    font-weight: 500;
}

#clientsList-wrapper .box .paper-header .client-list-tag-container .pending-tag {
    background: rgba(255, 150, 22, 1);
}


#clientsList-wrapper .box .name-container h6 {
	color:#000000;
	font-size: 1.2rem;
	font-family: "Poppins";
}

#clientsList-wrapper .box .name-container p {
	color:#8b8b8b;
	font-size: 0.9rem;
	font-family: "Poppins";
}

#clientsList-wrapper .box .paper-business-container {
	width: 95%;
	max-height: 125px;
	overflow-y: auto;
	margin: 0 auto !important;
	padding: 0.5rem !important;
	flex-grow: 1;
}

#clientsList-wrapper .box .paper-business-container h6 {
	font-size: 1.1rem;
	font-family: "Poppins";
	font-weight: 500;
	color:rgb(55, 55, 55);
	text-decoration: underline;
}

#clientsList-wrapper .box .paper-business-container span {
	font-size: 1rem;
	font-family: "Poppins";
	color:rgb(85, 85, 85);
}

#clientsList-wrapper .box .paper-business-container p {
	font-size: 1rem;
	font-family: "Poppins";
	font-weight: 500;
	color:rgba(85, 85, 85, 0.5);
}

#clientsList-wrapper .box .paper-return-container {
	height: 60px;
	width: 95%;
	margin: 0 auto !important;
	border-top: 1px solid rgba(85, 85, 85, 0.3);
}

#clientsList-wrapper .box .paper-return-container h6 {
	font-size: 1rem;
	font-family: "Poppins";
	font-weight: 500;
	color:rgb(85, 85, 85);
}

#clientsList-wrapper .box .paper-return-container h6 span {
	padding-left: 0.5rem;
	font-weight: 400;
	color:rgb(85, 85, 85);
}


#clientsList-wrapper .MuiInputBase-root {
  	border-color: transparent !important;
}

#clientsList-wrapper .MuiFormControl-root {
	width: 100%;
	height: 2.5rem;
}

#clientsList-wrapper .MuiInputBase-root fieldset {
  	border-color: transparent !important;
}

/* Style the search input field */
#clientsList-wrapper .field {
	flex: 1;
	height: 1.5rem;
	border-radius: 1.25rem;
	padding: 0.5rem 1rem;
	font-size: 1rem;
	font-weight: 600;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
	transition: box-shadow 0.3s ease-in-out;
}

/* Style the search input field on focus */
#clientsList-wrapper .field:focus {
	outline: none;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

/* Style the search input label */
#clientsList-wrapper label {
	font-size: 1rem;
	font-weight: 500;
	bottom: 10px !important;
}

#clientsList-wrapper .change-view-btn-wrapper button {
	border: 1px solid orange;
}

#clientsList-wrapper .change-view-btn-wrapper .active{
	background-color: orange;
}

#clientsList-wrapper .change-view-btn-wrapper button svg {
	fill: #afafaf;;
}

#clientsList-wrapper .change-view-btn-wrapper .active svg {
	fill: #fff;
}

/* --------------------------------------- */
/* Refunds View */
/* --------------------------------------- */

#refunds-wrapper {
  	padding-right: 1rem;
}

#refunds-wrapper .refunds-header{
  	margin-bottom: 50px;
}

#refunds-wrapper .refunds-header button {
	background-color: rgb(29, 53, 208);
	color: white;
	border: none;
	padding: 7px 35px;
	border-radius: 25px;
	text-transform: capitalize;
    font-size: 16px;
	font-weight: 500;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease;
	text-transform: none;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

#refunds-wrapper .refunds-header button:hover {
	background-color: rgb(16 33 148);
}

#refunds-wrapper .refund-card {
	height: 100%;
	display: flex;
	justify-content: space-between;
	padding: 20px;
	border-radius: 12px;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
	background-color: #fff;
	transition: box-shadow 0.2s ease;
	position: relative;
	border-left: 5px solid transparent;
}
  
#refunds-wrapper .refund-card.positive {
	border-left-color: #00770e;
}
  
#refunds-wrapper .refund-card.negative {
	border-left-color: #BF0000;
}
  
#refunds-wrapper .refund-info {
	display: flex;
	flex-direction: column;
	gap: 6px;
}

#refunds-wrapper .refund-info .MuiTypography-root {
	display: flex;
}
  
#refunds-wrapper .refund-info .label {
	width: 125px;
	display: inline-block;
	font-weight: 600;
	color: #1c1c1c;
	margin-right: 6px;
	font-family: "Poppins";
	font-size: 1.1rem;
}
  
#refunds-wrapper .refund-info span {
	font-weight: 400;
	color: #555;
	font-family: "Poppins";
	font-size: 1rem;
}
  
#refunds-wrapper .refund-link {
	flex: 1;
	font-family: "Poppins";
	color: #1976D2;
	font-size: 0.9rem;
	text-decoration: none;
	/* white-space: nowrap; */
	overflow: hidden;
	/* text-overflow: ellipsis; */
}
  
#refunds-wrapper .refund-delete {
	align-self: flex-start;
	padding: 4px;
	margin-left: 12px;
	color: #888;
}
  
#refunds-wrapper .refund-delete:hover {
	color: #000;
}
  

#admin-refunds-dialog .success-msg {
	padding-bottom: 2rem;
}

#admin-refunds-dialog .success-msg img {
  	width: 100px;
}

#admin-refunds-dialog .MuiIconButton-root:hover {
	color: #FFA500;
}

#admin-refunds-dialog .add-refunds-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#admin-refunds-dialog .add-refunds-btn:disabled {
	opacity: 0.7;
}
  
#admin-refunds-dialog .add-refunds-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0;
}

#admin-refunds-dialog .dialog-error-msg {
	margin-bottom: 2rem;
    font-weight: 500;
	color: #BF0000;
	text-transform: capitalize;
	font-family: "Poppins";
	letter-spacing: 1px;
	font-size: 1.3rem;
}

#admin-refunds-dialog .MuiFormHelperText-root.Mui-error {
	font-size: 0.7rem;
    position: absolute;
    bottom: -18px;
    margin-left: 2px;
}

/* --------------------------------------- */
/* Admin User Questionnaires view */
/* --------------------------------------- */

#questionnaires-admin-wrapper .MuiBreadcrumbs-li p {
	text-decoration: none;
	color: #444;
	font-family: 'Poppins';
	font-weight: 500;
}

#questionnaires-admin-wrapper .MuiBreadcrumbs-li .breadcrumb-main {
	color: #777;
	font-weight: 400;
}

#questionnaires-admin-wrapper .MuiBreadcrumbs-li .breadcrumb-main:hover {
	cursor: pointer;
	text-decoration: underline;
}

#questionnaires-admin-wrapper .MuiMenuItem-root {
  	font-weight: bold;
}

#questionnaires-admin-wrapper .MuiSelect-root {
  	padding: 10px;
}

#questionnaires-admin-wrapper .files-go-back-btn {
	border: 1px solid #682286;
    background-color: transparent;
    font-size: 1rem;
    text-transform: capitalize;
    font-family: 'Poppins';
    color: #682286;
    padding: 0.2rem 1rem;
    border-radius: 21px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	transition: all 0.5s ease;
}

#questionnaires-admin-wrapper .files-go-back-btn:hover {
	background-color: #682286;
	color: #fff;
}

#questionnaires-admin-wrapper .top-section {
	display: flex;
	justify-content: space-between;
	width: 90%;
}

#questionnaires-admin-wrapper .breadcrumb-container {
  	flex-grow: 1;
}

#questionnaires-admin-wrapper .year-select-container {
  	min-width: 150px;
	height: 45px;
}

#questionnaires-admin-wrapper .year-select-container .MuiInputBase-root {
	height: 45px;
}

#questionnaires-admin-wrapper .questionnaire-item {
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
	padding: 1rem;
	border-radius: 12px;
	background-color: #fff;
}

#questionnaires-admin-wrapper .questionnaire-item .content-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 0.5rem;
}

#questionnaires-admin-wrapper .questionnaire-item img {
  	width: 80px;
}

#questionnaires-admin-wrapper .questionnaire-item p {
	font-size: 1rem;
	font-family: "Poppins";
	color: #333;
	font-weight: 500;
}

#questionnaires-admin-wrapper .questionnaire-item .content-box .buttons-wrapper {
	display: flex;
	column-gap: 1rem;
	justify-content: center;
}

#questionnaires-admin-wrapper .questionnaire-item .content-box button {
	width: 45%;
	color: white;
	text-transform: capitalize;
	font-family: "Poppins";
	font-size: 1rem;
	padding: 0.3rem 1rem;
	transition: all 0.3s ease-in-out;
}

#questionnaires-admin-wrapper .questionnaire-item .content-box .view-btn {
	background-color: #293693;
}

#questionnaires-admin-wrapper .questionnaire-item .content-box .edit-btn {
	background-color: rgba(255, 150, 22, 1);
}

#questionnaires-admin-wrapper .questionnaire-item .content-box .view-btn:hover {
	background-color: #192fd7;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#questionnaires-admin-wrapper .questionnaire-item .content-box .edit-btn:hover {
	background-color: rgba(255, 120, 0, 1);
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#client-que-edit-dialog h5,
#client-que-view-dialog h5 {
	margin-bottom: 1.5rem;
	font-family: "Poppins";
	font-weight: 500;
	font-size: 1.7rem;
	color: #333;
}

#client-que-edit-dialog .group-heading, 
#client-que-view-dialog .group-heading{
	font-family: "Poppins";
	letter-spacing: 1px;
	color: #333;
	font-weight: 500;
	color: rgb(84, 84, 84);
}

#client-que-edit-dialog .update-client-que-btn {
	background-color: #1976D2;
	color: #FFFFFF;
	border-radius: 25px;
	font-weight: bold;
	padding: 8px 48px;
	text-transform: none;
	box-shadow: none;
	transition: all 0.3s ease;
}

#client-que-edit-dialog .update-client-que-btn:hover {
	box-shadow: 0px 2px 2px -1px #1976D2;
	background-color: #1565C0;
}

#admin-business-dialog h6 {
	text-align: center;
	font-family: "Poppins";
	letter-spacing: 1px;
	color: #333;
	font-size: 1rem;

}

#admin-business-dialog .cancel-button {
	max-width: 185px;
	width: 185px;
	background-color: transparent;
	border: 1px solid #4b4b4b74;
	color: #4B4B4B; 
	padding: 8px 5px;
	border-radius: 17px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;
}

#admin-business-dialog .archive-button {
	max-width: 185px;
	width: 185px;
	background-color: #181818bf;
	border: 1px solid #4b4b4b74;
	color: #fff; 
	padding: 8px 5px;
	border-radius: 17px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	transition: all 0.3s ease-in-out;
}

#admin-business-dialog .delete-button {
	max-width: 185px;
	width: 185px;
	background-color: #BF0000;
	color: white;
	border: 1px solid #BF0000;
	padding: 8px 5px;
	border-radius: 17px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

#admin-business-dialog .merge-button {
	max-width: 185px;
	width: 185px;
	background-color: #eb6505;
	color: white;
	border: 1px solid #eb6505;
	padding: 8px 5px;
	border-radius: 17px;
	font-size: 1.2rem;
	font-family: "Poppins";
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	text-transform: none;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

/* #admin-business-view-dialog h6 {
	text-align: left;
	font-size: 1.2rem;
}

#admin-business-view-dialog p {
	text-align: left;
	font-size: 1.1rem;
	color: #545454;
	font-family: Poppins;
}

#admin-business-view-dialog .other-users-title {
	text-transform: uppercase;
	font-weight: 600;
}

#admin-business-view-dialog .other-users-subtitle {
	text-decoration: underline;
    font-weight: 400;
    font-style: italic;
}

#admin-business-view-dialog .users-list span {
	font-family: 'Poppins';
    color: #545454;
    font-weight: 500;
    font-size: 1.1rem;
}

#admin-business-view-dialog .users-list p{
	font-family: 'Poppins';
    font-size: 1.1rem;
} */

.business-view-container {
	padding: 24px;
	background: #ffffff;
	border-radius: 12px;
	max-height: 90vh;
	overflow-y: auto;
}
  
.business-view-container .business-view-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
}

.business-view-container .business-view-chips {
	display: flex;
	gap: 10px;
}

.business-view-container .chip-view {
	background-color: #eaf7ea;
	color: #2e7d32;
	font-weight: 500;
}

.business-view-container .chip-frozen {
	background-color: #d0e9ff;
  	color: #0d47a1;
  	font-weight: 500;
}

.business-view-container .business-view-content {
	background-color: #f8f9fc;
	border-radius: 10px;
	padding: 24px;
}

.business-view-container .business-view-field {
	display: flex;
	justify-content: space-between;
	padding: 8px 0;
	border-bottom: 1px solid #e0e0e0;
}

.business-view-container .business-view-field label {
	font-weight: 600;
	color: #3f3f3f;
	font-family: "Poppins";
}

.business-view-container .business-view-field span {
	color: #444;
	font-family: "Poppins";
}

#admin-business-view-dialog.business-view-container .user-section {
	margin-top: 20px;
	padding: 16px;
	border-radius: 10px;
	background-color: white;
}
  
#admin-business-view-dialog.business-view-container .section-header {
	font-weight: 500;
	font-size: 1.1rem;
	margin-bottom: 8px;
	/* padding-bottom: 4px; */
	text-align: left !important;
	letter-spacing: 0.5px;
}
  
.business-view-container .active-header {
	color: #2e7d32;
	border-left: 4px solid #66bb6a;
	padding-left: 8px;
}
  
.business-view-container .frozen-header {
	color: #1565c0;
	border-left: 4px solid #64b5f6;
	padding-left: 8px;
}

.business-view-container .user-group-title {
	font-weight: 500;
	font-style: italic;
	color: #3a3a3a;
	margin-bottom: 8px;
	font-family: "Poppins";
}

.business-view-container .user-entry {
	display: flex;
	justify-content: space-between;
	padding: 6px 0;
}

.business-view-container .user-name {
	font-weight: 500;
	font-family: "Poppins";
}

.business-view-container .user-permission {
	font-size: 0.9rem;
	color: #555;
	font-family: "Poppins";
}

.user-section hr {
	margin-bottom: 15px;;
}

